import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../context";
import { redirectTo } from "../../../component/Redirect";
import TestList from "../../../component/TestList";

const UserTest = () => {
  const { state } = useContext(Context);
  const { user } = state;
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);

  useEffect(() => {
    if (user) {
      fetchTests(); // Fetch tests when user is available
    }
  }, [user]);

  const fetchTests = async () => {
    try {
      const userId = user.externalId;
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests`, {
        params: { userId },
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      setTests(response.data.responseData || []);
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };

  const handleTestSelect = (test) => {
    redirectTo(`/user/performance/${test.externalId}`);
  };

  return (
    <div>
      <div style={{
        backgroundColor: 'rgb(46 89 115)', padding: '26px', marginBottom: '20px', marginLeft: '-20px', height: '120px'
      }}>
        <h1 className="courses text-center" style={{ margin: 0, color: '#fff' }}> Test Performance </h1>
      </div>
      <div style={{ padding: '20px', maxHeight: '100vh', overflowY: 'auto' }}>
        <div style={{ maxHeight: 'calc(100vh - 80px)', overflowY: 'auto' }}>
          <TestList
            tests={tests}
            onTestSelect={handleTestSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTest;
