import { useState , useContext, useEffect} from "react";

import axios from 'axios';
import { toast } from "react-toastify";
import { Context } from "../context";
import LoadingSpinner from '../component/LoadingSpinner';
import { redirectTo } from "../component/Redirect";

const ForgotPassword = ()=>{

    const [email, setEmail]= useState('')
    const [success, setSuccess]= useState(false)
    const [code, setCode]= useState('')
    const [newPassword, setNewPassword]= useState('')
    const [loading, setLoading]= useState(false)

    const {state:{user}} = useContext(Context)


    useEffect (()=>{
        if(user != null) redirectTo("/")
    },[user]);

    const handSubmit = async(e)=>{
        e.preventDefault()

        try{
            setLoading(true)
            const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/user/forgot-password`,{email});
            setSuccess(true);
            toast("Check your email for the secret code")
        }catch(err){
           
            toast(err.response.data)
        }
        setLoading(false)
    };

    const handleResetPassword = async(e)=>{
        e.preventDefault()

        try{
            setLoading(true)
            const {data} = await axios.post(process.env.REACT_APP_BASE_URL + "/v1/user/reset-password",{email, code, newPassword});
            setEmail('')
            setCode('')
            setNewPassword('')
            setSuccess(true);
            toast("password resetted,redirecting to login")
            redirectTo("/login")
        }catch(err){
            toast(err.response.data)
        }
        setLoading(false)
    };

    return(
        <>
        <h1 className="text-center square">
            Forgot Paswword
        </h1>
        <div className="container col-md-4 offset-md-4 pb-3">
        <form onSubmit={success ? handleResetPassword: handSubmit}>
            <input type="email" className="form-control mb-4 p-4"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            placeholder="Enter email"
            required/>
            {loading ? <LoadingSpinner/> :null}

            { success && 
            
            <>
                <input type="secret code" className="form-control mb-4 p-4"
                value={code}
                onChange={(e)=>setCode(e.target.value)}
                placeholder="Enter secret code"
                required/>
                    <input type="password" className="form-control mb-4 p-4"
                value={newPassword}
                onChange={(e)=>setNewPassword(e.target.value)}
                placeholder="Enter new password"
                required/>
               
            </> }
            <button className="btn btn-primary btn-block p-2 btnForgot" 
            disabled= {loading || !email}>
                Submit
            </button>
           
        </form>
        
        </div>
        </>
    );

};

export default ForgotPassword;