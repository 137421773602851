import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../context';
import axios from 'axios';
import SuccessPopup from '../../../component/SuccessPopup';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Define your API endpoints

function CommentFeature({ courseId, topicId }) {
  const [comment, setComment] = useState('');
  const [commentsList, setCommentsList] = useState([]);
  const [replies, setReplies] = useState([]);
  const [reply, setReply] = useState('');
  const [nestedReply, setNestedReply] = useState('');
  const [showReplyBox, setShowReplyBox] = useState(null);
  const [showNestedReplyBox, setShowNestedReplyBox] = useState(null);
  const [expandedComment, setExpandedComment] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { state } = useContext(Context);
  const { user } = state;

  // Fetch comments from API
  useEffect(() => {
    if (user) fetchComments();
  }, [topicId, currentPage, user]);

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/comments`, {
        params: { pageNo: currentPage, pageLimit: 6 },
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      const { comments, total } = response.data.responseData;
      setCommentsList(comments || []);
      setTotalPages((total % 6 > 0 ? (total / 6 + 1) : total / 6) || 1);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const fetchReplies = async (index) => {
    try {
      const parentCommentId = commentsList[index].externalId;
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/replies`, {
        params: { parentCommentId: parentCommentId, pageNo: 1, pageLimit: 6 },

        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      setReplies(response.data.responseData || []);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentChange = (e) => setComment(e.target.value);

  const handleSubmitComment = async () => {
    if (comment.trim()) {
      try {
        let { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/comments`,
          {
            comment: comment,
            userId: user.externalId
          }, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        }
        );

        handleShowPopup();
        setComment('');
        fetchComments();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleLike = async (commentId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/likes`,
        null, // This is the request body, which is `null` in this case
        {
          params: { commentId },
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        }
      );
      fetchComments();
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  const handleReplyLike = async (replyId, index) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/likes`, null, {
        params: { commentId: replyId },

        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      fetchReplies(index);
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  const handleReplyClick = (index) => {
    if (showReplyBox === index) {
      setShowReplyBox(null);
      setShowNestedReplyBox(null);
      setExpandedComment(null);
      setReplies([]);
      return;
    } else {
      setShowReplyBox(index);
      setShowNestedReplyBox(null);
      setExpandedComment(index);
      fetchReplies(index);
    }
  };

  const handleToggleReplies = (index) => {
    if (expandedComment === index) {
      setExpandedComment(null); // Hide replies if already expanded
    } else {
      setExpandedComment(index); // Show replies for this comment
      if (!replies[commentsList[index].externalId]) {
        fetchReplies(commentsList[index].externalId); // Fetch replies if not already fetched
      }
    }
  };

  const handleReplySubmit = async (index) => {
    if (reply.trim()) {
      const commentId = commentsList[index].externalId;

      try {
        let { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/comments`,
          {
            comment: reply,
            userId: user.externalId,
            parentCommentId: commentId
          }, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        }
        );

        handleShowPopup();
        setReply('');
        fetchReplies(index);
      } catch (err) {
        console.log(err);
      }
    }
  };


  const toggleExpand = (index) => {
    setExpandedComment(expandedComment === index ? null : index);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div >
      <div style={styles.container}>
        <SuccessPopup
          message="Comment posted successfully!"
          isVisible={isPopupVisible}
          onClose={handleClosePopup}
        />
        <textarea
          value={comment}
          onChange={handleCommentChange}
          placeholder="Write a comment..."
          style={styles.textarea}
        />
        <button onClick={handleSubmitComment} style={styles.button}>Post</button>

        <div style={styles.commentsSection}>
          {commentsList.map((comment, index) => (
            <div key={comment.externalId} style={styles.comment}>
              <div style={styles.commentHeader}>
                <FontAwesomeIcon icon={faUserCircle} style={styles.profileIcon} />
                <p style={styles.commentText}>{comment.comment}</p>
              </div>
              <div style={styles.actions}>
                <button onClick={() => handleLike(comment.externalId)} style={styles.likeButton}>
                  Like ({comment.noOfLikes})
                </button>
                <button onClick={() => handleReplyClick(index)} style={styles.replyButton}>
                  Reply
                </button>
                <button onClick={() => handleReplyClick(index)} style={styles.replyButton}>
                  {expandedComment === index ? 'Hide Replies' : 'Show Replies'}
                </button>
              </div>

              {showReplyBox === index && (
                <div style={styles.replyInput}>
                  <input
                    type="text"
                    value={reply}
                    onChange={(e) => setReply(e.target.value)}
                    placeholder="Write a reply..."
                    style={styles.replyTextInput}
                  />
                  <button onClick={() => handleReplySubmit(index)} style={styles.replySubmitButton}>
                    Post
                  </button>
                </div>
              )}

              {expandedComment === index && replies.length > 0 && (
                <div style={styles.repliesSection}>
                  {replies.length > 3 ? (
                    <div style={styles.scrollableReplies}>
                      {replies.map((reply, replyIndex) => (
                        <div key={reply.externalId} style={styles.reply}>
                          <div style={styles.commentHeader}>
                            <FontAwesomeIcon icon={faUserCircle} style={styles.profileIcon} />
                            <p style={styles.commentText}>{reply.comment}</p>
                          </div>
                          <div style={styles.actions}>
                            <button onClick={() => handleReplyLike(reply.externalId, index)} style={styles.likeButton}>
                              Like ({reply.noOfLikes})
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    replies.map((reply, replyIndex) => (
                      <div key={reply.externalId} style={styles.reply}>
                        <div style={styles.commentHeader}>
                          <FontAwesomeIcon icon={faUserCircle} style={styles.profileIcon} />
                          <p style={styles.commentText}>{reply.comment}</p>
                        </div>
                        <div style={styles.actions}>
                          <button onClick={() => handleReplyLike(reply.externalId, index)} style={styles.likeButton}>
                            Like ({reply.noOfLikes})
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          ))}

          {/* Pagination Controls */}
          <div style={styles.pagination}>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                style={styles.pageButton}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// Inline CSS styles
const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  header: {
    marginBottom: '10px',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    height: '110px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginBottom: '10px',
    fontFamily: 'Arial, sans-serif',
  },
  profileImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#1877f2',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontFamily: 'Arial, sans-serif',
    width:'30%',
    display:'flex',
    justifyContent:'center',
    margin:'0px auto'
  },
  commentsSection: {
    marginTop: '20px',
  },
  comment: {
    padding: '10px',
    borderBottom: '1px solid #eee',
    marginBottom: '10px',
  },
  commentText: {
    margin: '5px 7px 5px',
    fontSize: '14px',
    color: '#333',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  likeButton: {
    backgroundColor: 'transparent',
    color: '#1877f2',
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
    borderRadius: '4px',
    fontSize: '14px',
  },
  replyButton: {
    backgroundColor: 'transparent',
    color: '#1877f2',
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
    borderRadius: '4px',
    fontSize: '14px',
  },
  replyInput: {
    display: 'flex',
    marginTop: '10px',
  },
  replyTextInput: {
    flexGrow: 1,
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontFamily: 'Arial, sans-serif',
  },
  replySubmitButton: {
    marginLeft: '10px',
    backgroundColor: '#1877f2',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '5px 10px',
    fontFamily: 'Arial, sans-serif',
  },
  repliesSection: {
    marginTop: '10px',
    paddingLeft: '20px',
  },
  scrollableReplies: {
    maxHeight: '150px', // Adjust this height as needed
    overflowY: 'auto',
  },
  reply: {
    padding: '5px',
    borderBottom: '1px solid #f0f0f0',
    fontSize: '14px',
    color: '#333',
  },
  pagination: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  pageButton: {
    padding: '5px 10px',
    margin: '0 5px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
  },
};

export default CommentFeature;
