import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Badge, Box, styled, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import SingleCourseJumbotron from '../../component/cards/SingleCourseJumbotron';
import PreviewModal from '../../component/modal/PreviewModal';
import SingleCourseLessions from '../../component/cards/SingleCourseLessons';
import { Context } from "../../context";
import { redirectTo } from "../../component/Redirect";
import { toast } from "react-toastify";
import CommentBox from '../user/comment/CommentBox'
import CircularProgress from '@mui/material/CircularProgress';

const SingleCourse = () => {
    const { slug } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [preview , setPreview] = useState("");
    const [course , setCourse] = useState(null);
    const [topics , setTopics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [enrolled, setEnrolled] = useState(false);
    const [showCommentBox, setShowCommentBox] = useState(false)
    const {
        state: { user },
    } = useContext(Context);

    const videoRef = useRef(null);

    useEffect(() => {
        console.log("Calling useEffect");
        if (course && user) {
            setShowCommentBox(true)
            checkEnrollment();
            return;
        }
        console.log("Calling useEffect: load course");
        // Simulate getCourseData(slug) since slug is not available
        if(!course)getCourseData(slug);
    }, [user, course, slug]);  

    const playVideo = () => {
        videoRef.current.play();
    };

    const pauseVideo = () => {
        videoRef.current.pause();
    };

    const handlePaidEnrollment = async () => {
        console.log("handle paid enrollment");
        try {
            setLoading(true);
            if (!user) {
                redirectTo("/login");
                return;
            }


            console.log("Paid enrollment logic");
            if (enrolled) {
                redirectTo(`/user/course/${course.name}`);
                return;
            }
            redirectTo(`/user/course/buy/${course.name}`);
            setLoading(false);
        } catch (err) {
            toast("Enrollment Failed");
            console.log(err);
        }
    };

    const handleFreeEnrollment = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            if (!user) {
                redirectTo("/login");
                return;
            }

            if (enrolled) {
                redirectTo(`/user/course/${course.name}`);
                return;
            }

            const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/user/subscribe`,{
                userId: user.externalId,
                courseId: course.externalId,
                enrollmentType:'free'
            }, {
                headers:{
                    Authorization: `Bearer ${user.accessToken}`,
                    "Content-Type": "application/json",
                }
            })
            // Your free enrollment logic
            // Replace this with your actual logic
            console.log("Free enrollment logic");

            setLoading(false);
            setEnrolled(true);
        } catch (err) {
            toast("Enrollment Failed");
            console.log(err);
            setLoading(false);
        }
    };

    const getCourseData = async (slug) => {
        try {
            setLoading(true)
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${slug}`);
            const { data: topicResponse } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${data.responseData.externalId}/topics/list`);

            const courseData = data?.responseData;
            const topics = topicResponse?.responseData;

            if (!courseData || !topics) {
                console.log("Course data or topics not available");
                return;
            }

            setCourse(courseData);
            setTopics(topics);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
            redirectTo("/");
        }finally {
            setLoading(false);
        }
    };

    const checkEnrollment = async () => {
        try {

            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/user/${user.externalId}/courses/${course.externalId}`,
                {
                    headers:{
                        Authorization: `Bearer ${user.accessToken}`,
                        "Content-Type": "application/json",
                    }
                }
            );

            setEnrolled(data.responseData);
        } catch (err) {
            toast("Failed to check enrollment");
            console.log(err);
            setEnrolled(false);
        }
    };

    return (
        <>  {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                  <CircularProgress color="primary" />
                </div>
              )}
            {course && !loading && (
                <SingleCourseJumbotron 
                    course={course}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    preview={preview}
                    setPreview={setPreview}
                    topics={topics}
                    user={user}
                    loading={loading}
                    handleFreeEnrollment={handleFreeEnrollment}
                    handlePaidEnrollment={handlePaidEnrollment}
                    enrolled={enrolled}
                />
            )}
        
          </>
    );
};

export default SingleCourse;
