// src/MyProfile.js
import React, { useContext, useEffect, useState } from 'react';
import { FaUserCircle, FaSave, FaEdit } from 'react-icons/fa';
import '../../css/profile.css'; // Import the CSS file for styling
import { Context } from '../../context';
import axios from 'axios';
import { redirect } from 'react-router-dom';
import { redirectTo } from '../../component/Redirect';
import { color } from '@codemirror/theme-one-dark';

const MyProfile = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [newEmail, setNewEmail] = useState(email);
    const [newPassword, setNewPassword] = useState('');
    const [editingEmail, setEditingEmail] = useState(false);
    const [editingPassword, setEditingPassword] = useState(false);
    const { state,dispatch } = useContext(Context)
    const { user } = state
   

    const handleSavePassword = async (e) => {
        e.preventDefault();
        if (newPassword.length >= 8 && /[0-9]/.test(newPassword) && /[A-Z]/.test(newPassword)) {
            // Call your API here
            try {
                const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/v1/user/${user.externalId}/update`, { password: newPassword },
                    {
                        headers: {
                            Authorization: "Bearer " + user.accessToken,
                            "Content-Type": "application/json",
                        }
                    }
                );

            } catch (error) {
                console.log("Error" + error)

            }
            setNewPassword(null)
            setEditingEmail(false);
        } else {
            alert('Password must be at least 8 characters long and include a number and a capital letter.');
        }
        setEditingPassword(false);
    };

    return (
        <div className="profile-container">
            <div className="profile-card">
                <FaUserCircle size={100} className="user-icon" style={{color:'rgb(46 89 115)'}}/>
                <h2>{user ? user.username:''}</h2>

                <div className="profile-info">
                    <div className="profile-info-row">
                        <span>Email: {user ? user.email: ''}</span>
                    </div>

                    <div className="profile-info-row">
                        <span>Password: ****</span>
                        {editingPassword ? (
                            <div className="profile-info-edit">
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <FaSave onClick={handleSavePassword} className="save-icon" />
                            </div>
                        ) : (
                            <FaEdit onClick={() => setEditingPassword(true)} className="edit-icon" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
