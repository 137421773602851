import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import AdSense from '../../component/AdSense';

function PostPage() {
    const { slug } = useParams();

    const [post, setPost] = useState();

    useEffect(() => {
        if (slug) loadPost();
    }, [slug]);

    const loadPost = async () => {
        try {
            let { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/posts/${slug}`);
            setPost(data.responseData);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="post-page-container">
            {post &&
                <div className="post-content">
                    
                    <h2 className="post-title">{post.title}</h2>

                    <p className="post-info">
                        <a className="author">{post.createdBy}</a>
                        {/* <time>{format(post.createdAt, 'MMM d, yyyy')}</time> */}
                    </p>
                    
                    <hr />
                    <div className="post-image">
                        <img src={post.coverImageUrl} alt="Post Cover" />
                    </div>
                    <hr/>
                    {/* <AdSense  />  */}

                    <div className="post-details">
                        <p className="post-summary">{post.summary}</p>
                        <div dangerouslySetInnerHTML={{ __html: post.content }}  style={{
                        maxWidth: '100%',
                        overflowWrap: 'break-word',
                    }}></div>

                    </div>
                </div>
            }
        </div>
    )
}


export default PostPage;