import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { redirectTo } from "../Redirect";
import { Context } from "../../context";
import LoadingSpinner from "../LoadingSpinner";

const StudentRoute = ({ children }) => {
  const [ok, setOk] = useState(false);
  const { state } = useContext(Context);
  const { user } = state;

  useEffect(() => {

    const fetchUser = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/user/current`, // Replace with your API base URL
          {
            headers: {
              Authorization: "Bearer " + user.accessToken,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(data);
        setOk(true);
      } catch (err) {
        console.log(err);
        alert("Session expired or Invalid Token");

        setOk(false);
        window.localStorage.removeItem("user");
        redirectTo("/login"); // Redirect to login page on error or unauthorized access
      }
    };

    if (!user) {
      const storedUser = window.localStorage.getItem("user");
      if (!storedUser) {
        // User is not logged in, redirect to login page or show login component
       redirectTo("/login"); // Use history.push for navigation
      }
    }
    if (user) {
      fetchUser();
    }
  }, [user]);

 

  return (
    <>
      {!ok ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">{children}</div>
          </div>
        </>
      )}
    </>
  );
};

export default StudentRoute;
