import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Box,
  Paper,
  Container,
  Chip
} from '@mui/material';
import axios from 'axios';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { redirectTo } from '../Redirect';
import SuccessPopup from '../SuccessPopup';
import CodeEditor from '../CodeEditor';

const SafeHTML = ({ html }) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const TestModal = ({ showTest, setShowTest, test, user, setShowTestList, courseCode }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [timer, setTimer] = useState(1800); // Default to 30 minutes if testDuration is not set
  const [timerRunning, setTimerRunning] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (showSuccessPopup) {

      redirectTo(`/user/course/${courseCode}`); // Redirect after 3 seconds

    }
  }, [showSuccessPopup]);

  useEffect(() => {
    if (test) {
      fetchQuestions(test.externalId);
      setTimer(test.testDuration * 60 || 1800); // Convert minutes to seconds
    }
  }, [test]);

  useEffect(() => {
    let interval;
    if (timerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      if (timer === 0) {
        handleSubmit();
      }
    }
    return () => clearInterval(interval);
  }, [timerRunning, timer]);

  const fetchQuestions = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests/${testId}/questions`, {
        params: {
          page: 1,
          size: 40
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      if (response.data.responseData && response.data.responseData.length > 0) {
        setQuestions(response.data.responseData);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const handleOptionChange = (event) => {
    const { name, value, checked } = event.target;
    setAnswers(prevAnswers => {
      const currentAnswers = prevAnswers[name] || [];
      if (checked) {
        return { ...prevAnswers, [name]: [...currentAnswers, value] };
      } else {
        return { ...prevAnswers, [name]: currentAnswers.filter(val => val !== value) };
      }
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = async () => {
    const submittedAnswers = questions.map((question) => ({
      question: question.externalId,
      answers: answers[`question${question.externalId}-answers`] || []
    }));

    console.log('Submitted Answers:', submittedAnswers); // Debug log

    try {
      // Uncomment the line below when you are ready to submit answers
      await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/tests/${test.externalId}/submit`, { userId: user.externalId, userResponse: submittedAnswers },
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        }
      );
      setShowSuccessPopup(true);
    } catch (error) {
      console.error('Error submitting test:', error);
    } finally {
      stopTimer();

    }
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(0);
    setAnswers({});
    setTimer(test.testDuration * 60 || 1800); // Reset timer based on testDuration or default to 30 minutes
    setTimerRunning(true);
    setShowResults(false);
  };

  const handleClose = () => {
    setShowTest(false);
  };

  const stopTimer = () => {
    setTimerRunning(false);
  };

  const calculateScore = () => {
    return 0;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty.toLowerCase()) {
      case 'easy':
        return 'green';
      case 'medium':
        return 'blue';
      case 'hard':
        return 'red';
      default:
        return 'black'; // Default color if difficulty is not one of the above
    }
  };


  // Check if there are questions and if the current question exists
  const currentQuestion = questions[currentQuestionIndex] || {};

  return (
    <Container maxWidth="md"> {/* Adjust 'md' to your preferred size */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            {test.testName || 'Test Name'} {/* Display the test name */}
          </Typography>
          {questions[currentQuestionIndex] && <Typography variant="body1" align="right">
          <span style={{ color: 'black' }}>LEVEL: </span>
          <span style={{ color: getDifficultyColor(questions[currentQuestionIndex].difficulty) }}>
            {questions[currentQuestionIndex].difficulty}
          </span>
        </Typography>
          }
          <Typography variant="body1" align="right">
            Time Remaining: {formatTime(timer)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1} >
              {questions.map((_, index) => (
                <Chip
                  key={index}
                  label={index + 1}
                  style={{paddingBottom:'4px'}}
                  color={index === currentQuestionIndex ? 'success' : 'default'}
                  onClick={() => setCurrentQuestionIndex(index)}
                  variant={index === currentQuestionIndex ? 'filled' : 'outlined'}
                />
              ))}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className='questions'>
            <Typography variant="h6" gutterBottom>
              {`Q${currentQuestionIndex + 1}.`}<SafeHTML html={currentQuestion.description || ''} />
            </Typography>
          </div>
        </Grid>
        {questions[currentQuestionIndex] && questions[currentQuestionIndex].type === "CODE" && <Grid item xs={12}><CodeEditor questionId={questions[currentQuestionIndex].externalId} user={user} initialCode={questions[currentQuestionIndex].defaultCode}/> </Grid>}

        {currentQuestion.options && currentQuestion.options[0].optionDescription ? <div className="container optionContainer">
          <div className="option-wrapper">
            {currentQuestion.options?.map((option) => (
              <div className="option-item" key={option.optionId}>
                <Checkbox
                  checked={answers[`question${currentQuestion.externalId}-answers`]?.includes(option.optionId) || false}
                  onChange={handleOptionChange}
                  name={`question${currentQuestion.externalId}-answers`}
                  value={option.optionId}
                />
                <div className="optionBox">
                  <SafeHTML html={option.optionDescription} />
                </div>
              </div>

            ))}
          </div>
        </div> : null}

        <Grid item xs={12} container justifyContent="center" spacing={2}>
          <Grid item>
            {currentQuestionIndex > 0 && (
              <Button variant="outlined" onClick={handlePrevQuestion}>Previous Question</Button>
            )}
          </Grid>
          <Grid item>
            {currentQuestionIndex < questions.length - 1 && (
              <Button variant="outlined" onClick={handleNextQuestion}>Next Question</Button>
            )}
          </Grid>
          <Grid item>
            {currentQuestionIndex === questions.length - 1 && (
              <Button variant="contained" onClick={handleSubmit}>Submit Answers</Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* coding platform */}
      {showSuccessPopup && (
        <SuccessPopup
          message="Test submitted successfully!"
          isVisible={showSuccessPopup}
          onClose={() => setShowSuccessPopup(false)}
        />
      )}
    </Container>
  );
};

export default TestModal;
