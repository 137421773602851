import React, { useContext, useEffect, useState } from 'react';
import { redirectTo } from '../component/Redirect';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Context } from '../context';

const PaymentSuccessPage = () => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const { state } = useContext(Context)
    const { user } = state;
    const { orderId } = useParams();
    useEffect(() => {
        const verifyPayment = async () => {

            try {
                const uri = `${process.env.REACT_APP_BASE_URL}` + `/v1/orders/verify-payment` + `/${orderId}/status`;
                const response = await axios.get(uri, {
                    headers: {
                        Authorization: "Bearer " + user.accessToken,
                        "Content-Type": "application/json",
                    }
                });
                if (response.status == 200) {
                    redirectTo(`/user/thank-you?orderId=${orderId}&paymentId=${response.data.responseData.paymentReferenceId}&status=${response.data.responseData.status}`)
                } else {
                    setError('Payment verification failed.');
                }
            } catch (err) {
                setError('An error occurred while verifying the payment.');
            } finally {
                setLoading(false);
            }
        };

        verifyPayment();
    }, [orderId, user]);

    if (loading) return <p>Loading...</p>;

    return (
        <div className="payment-success">
            <div>
                {error ? <p>{error}</p> : <p>Payment verification in progress...</p>}
            </div>
        </div>
    );
};

export default PaymentSuccessPage;
