import React, { useState, useContext, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Context } from '../context';
import { redirectTo } from './Redirect';
import '../css/TopNav.css';

const TopNav = () => {
  const { state, dispatch } = useContext(Context);
  const { user } = state;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference for the dropdown

  const fetchUser = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/user/current`, // Replace with your API base URL
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data);
    } catch (err) {
      console.log(err);
      alert("Session expired or Invalid Token");
      window.localStorage.removeItem("user");
    }
  };

  useEffect(() => {
    if (user) fetchUser();
  }, [user]);

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close dropdown if clicked outside
      }
    };

    // Add event listener for clicks on the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const logout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGOUT_URL}`, {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      });

      window.localStorage.removeItem('user');
      dispatch({ type: 'LOGOUT' });
      redirectTo('/login');
    } catch (error) {
      window.localStorage.removeItem('user');
      toast.error(error.response?.data?.statusInfo?.errorMessage || 'Error logging out');
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top shadow-sm">
      <a className="navbar-brand" href="/">
        <img src='https://course-image-data.s3.ap-south-1.amazonaws.com/banner/codecampus-logo.png' alt="Logo" className="logo" />
      </a>

      <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/" activeClassName="active">Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/about" activeClassName="active">About</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contact" activeClassName="active">Contact</NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink className="nav-link" to="/blog" activeClassName="active">Blog</NavLink>
          </li> */}
          {user && user.role === 'instructor' && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/instructor" activeClassName="active">Instructor</NavLink>
            </li>
          )}
        </ul>

        <ul className="navbar-nav">
          {!user ? (
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to="/login" activeClassName="active">Login</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/register" activeClassName="active">Signup</NavLink>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item dropdown toggleMenu" ref={dropdownRef}>
                <button
                  className="nav-link dropdown-toggle btn btn-link"
                  id="navbarDropdown"
                  onClick={handleDropdownToggle}
                  aria-expanded={dropdownOpen}
                  aria-haspopup="true"
                >
                  <img src='https://course-image-data.s3.ap-south-1.amazonaws.com/profile-icon.png' alt="User" className="rounded-circle user-avatar" /> {user.username}
                </button>
                {dropdownOpen && (
                  <div className="dropdown-menu show dropMenu" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item" href="/user">Dashboard</a>
                    <a className="dropdown-item" href="#" onClick={(e) => { e.preventDefault(); logout(); }}>Logout</a>
                  </div>
                )}
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default TopNav;
