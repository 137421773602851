import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Title } from "chart.js";
import { Context } from "../../context";
import axios from "axios";

// Registering chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Title);

const MyRewards = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [monthlySalesData, setMonthlySalesData] = useState(null);
    const [monthlyEarningsData, setMonthlyEarningsData] = useState(null);
    const [coupon, setCoupon] = useState({});
    const { state } = useContext(Context);
    const { user } = state;

    const fetchSalesData = async (year) => {
        const userId = user.externalId;
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/orders/users/${userId}/sales`, {
            params: { year },
        
            headers: {
                Authorization: "Bearer " + user.accessToken,
                "Content-Type": "application/json",
              }
        });
        return response.data.responseData;
    };

    const fetchCouponDetails = async () => {
        try {
            const userId = user.externalId;
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/user/${userId}/coupon/active`,{
                headers: {
                    Authorization: "Bearer " + user.accessToken,
                    "Content-Type": "application/json",
                  }
            });
            return response.data.responseData;
        } catch (error) {
            console.log("No active coupon found");
            return null;
        }
    };

    const fetchEarningsData = async (year) => {
        const userId = user.externalId;
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/orders/users/${userId}/earnings`, {
            params: { year },
            headers: {
                Authorization: "Bearer " + user.accessToken,
                "Content-Type": "application/json",
              }
        });
        return response.data.responseData;
    };

    const transformEarningsData = (apiData) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const earningsMap = {};
        const paidMap = {};

        months.forEach(month => {
            earningsMap[month] = 0;
            paidMap[month] = 0;
        });

        apiData.forEach(entry => {
            const month = entry.month.charAt(0).toUpperCase() + entry.month.slice(1).toLowerCase();
            if (earningsMap.hasOwnProperty(month)) {
                earningsMap[month] = parseFloat(entry.earnings) || 0; // Convert to float
                paidMap[month] = parseFloat(entry.totalPaid) || 0;   // Convert to float
            }
        });

        return {
            labels: months,
            datasets: [
                {
                    label: "Total Earnings",
                    data: months.map(month => earningsMap[month]),
                    backgroundColor: "#1976d2",
                },
                {
                    label: "Total Paid",
                    data: months.map(month => paidMap[month]),
                    backgroundColor: "#ff9800",
                },
            ],
            totalEarnings: Object.values(earningsMap).reduce((a, b) => a + b, 0),
            totalPaid: Object.values(paidMap).reduce((a, b) => a + b, 0)
        };
    };

    const getMonthlyData = (apiData) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const dataMap = {};

        months.forEach(month => {
            dataMap[month] = 0;
        });

        apiData.forEach(entry => {
            const month = entry.month.charAt(0).toUpperCase() + entry.month.slice(1).toLowerCase();
            if (dataMap.hasOwnProperty(month)) {
                dataMap[month] = entry.sales;
            }
        });

        return {
            labels: months,
            datasets: [
                {
                    label: "Courses Sold",
                    data: months.map(month => dataMap[month]),
                    backgroundColor: "#4caf50",
                },
            ],
            totalSales: Object.values(dataMap).reduce((a, b) => a + b, 0)
        };
    };

    useEffect(() => {
        const fetchData = async () => {
            const apiData = await fetchSalesData(selectedYear);
            const earningsData = await fetchEarningsData(selectedYear);
            const coupon = await fetchCouponDetails();
            setMonthlySalesData(getMonthlyData(apiData));
            setMonthlyEarningsData(transformEarningsData(earningsData));
            if (coupon) setCoupon(coupon);
        };

        if (user) fetchData();
    }, [selectedYear, user]);

    const handleYearChange = (event) => {
        setSelectedYear(Number(event.target.value));
    };

    const earningsOptions = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const datasetLabel = tooltipItem.dataset.label;
                        const datasetValue = tooltipItem.raw;
    
                        if (datasetLabel === "Total Earnings") {
                            return `₹ ${datasetValue.toLocaleString()} - Earnings`;
                        } else if (datasetLabel === "Total Paid") {
                            return `₹ ${datasetValue.toLocaleString()} - Paid`;
                        }
                        
                        return `${datasetLabel}: ₹ ${datasetValue.toLocaleString()}`;
                    }
                },
            },
        },
    };

    const salesOptions = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.raw} courses sold`,
                },
            },
        },
    };

    return (
        <div>
              <div style={{
                    backgroundColor: 'rgb(46 89 115)', padding: '26px', marginBottom: '20px', marginLeft: '-20px',height:'120px'
                }}>
                    <h1 className="courses text-center" style={{ margin: 0, color: '#fff' }}> Rewards </h1>
                </div>
            
            {coupon && (
                <div style={{ marginBottom: '20px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th colSpan="2" style={{ backgroundColor: '#f4f4f4', padding: '10px', textAlign: 'left' }}>Coupon Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>My Coupon Code:</td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{coupon.code}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>Coupon Off:</td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>₹{coupon.amount}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>UPI NO</td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>{coupon.upiNo}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            <div className="year-selection" style={{ margin: '10px' }}>
                <label htmlFor="year-select">Select Year:</label>
                <select id="year-select" value={selectedYear} onChange={handleYearChange}>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    {/* Add more years as needed */}
                </select>
            </div>
            {/* Total Earnings and Total Paid */}
            {monthlyEarningsData && (
                <div style={{ marginBottom: '20px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th colSpan="2" style={{ backgroundColor: '#f4f4f4', padding: '10px', textAlign: 'left' }}>Earnings Summary for {selectedYear}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>Total Earnings:</td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>₹{monthlyEarningsData.totalEarnings.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>Total Paid:</td>
                                <td style={{ padding: '10px', border: '1px solid #ddd' }}>₹{monthlyEarningsData.totalPaid.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            

           

            {/* Monthly Sales Chart */}
            <div style={{ margin: "30px 0" }}>
                <h3 style={{ textAlign: 'left' }}>Monthly Courses Sold in {selectedYear}</h3>
                <div style={{ width: "100%", height: "400px", margin: "0 auto" }}>
                    {monthlySalesData ? (
                        <Bar data={monthlySalesData} options={salesOptions} />
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>

            <div style={{ margin: "30px 0" }}>
                <h3 style={{ textAlign: 'left' }}>Monthly Earnings and Paid in {selectedYear}</h3>
                <div style={{ width: "100%", height: "400px", margin: "0 auto" }}>
                    {monthlyEarningsData ? (
                        <Bar data={monthlyEarningsData} options={earningsOptions} />
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
    </div>
    );
};

export default MyRewards;
