import {useReducer, createContext, useEffect} from "react"
import axios from "axios";

//initial state
const initialState ={
    user: null,
};

//create context

const Context = createContext()
//root reducer
const  rootReducer = (state, action)=>{
     switch(action.type){
        case "LOGIN":
            return {...state, user:action.payload};
        case "LOGOUT":
            return {...state,user: null};
        default:
            return state;
     }
};

//context provider

const Provider = ({children})=>{
    const [state,dispatch] = useReducer(rootReducer, initialState)
    
    useEffect(()=>{
        const storedUser = window.localStorage.getItem('user');
        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                dispatch({ type: "LOGIN", payload: parsedUser });
            } catch (error) {
                console.error("Error parsing stored user:", error);
            }
        }
    },[]);
    
    axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';
    axios.defaults.xsrfCookieName = 'XSRF-TOKEN';

   
    const {user} = state
    axios.interceptors.response.use(
    
        function(response){
            // any status code that lie within range of 2XX cause this function to trigger
            return response;
        },function(error){
            //any status codes that falls outside the range of 2XX cause this function to rtrigger
            let res = error.response;
            try{
                if(res.status == 401 && res.config && !res.config.__isRetryRequest){
                   
                    dispatch({type:"LOGOUT"});
                     window.localStorage.removeItem('user');

                }
             }catch(err1){
                console.log("error ",err1);
               
             }
            return Promise.reject(error)
        }
       
    )

    //implement later

    // useEffect(()=>{
    //     const getCsrfToken = async()=>{
    //         const {data} = await axios.get(process.env.NEXT_PUBLIC_BASE_URL+"/v1/csrf-token")
    //         console.log("CSRF",data)
    //         axios.defaults.headers['X-XSRF-Token']= data;

    //     };
    //     getCsrfToken();
    // },[])

    return (
        <Context.Provider value={{state, dispatch}}>
           {children}
        </Context.Provider>
    );
};

export {Context, Provider}