import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function VerifyPage() {
  const { token } = useParams(); 
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.post('https://byteu.in/verify', { token });
        
        if (response.data.success) {
          setMessage('Thank you! Your verification was successful.');
        } else {
          setMessage('Verification failed. Please try again.');
        }
      } catch (error) {
        setMessage('An error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      verifyToken();
    } else {
      setMessage('Token not provided.');
      setLoading(false);
    }
  }, [token]);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
}

export default VerifyPage;
