import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { MenuItem, Button, Typography } from "@mui/material";
import PdfViewer from "../../../component/PdfViewer";
import TestModal from "../../../component/modal/TestModal";
import Test from "../../../component/Test";
import { Context } from "../../../context";
import DOMPurify from 'dompurify'; // Import DOMPurify
import CodeEditor from "../../../component/CodeEditor";
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../css/user-course.css'
import LockIcon from '@mui/icons-material/Lock';
import ImageModal
 from "../../../component/modal/ImageModal";
import {
  Accordion,  
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton
} from '@mui/material';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css'
import '../../../css/code.css'

const UserCourse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState('');
  const [lessonId, setLessonId] = useState(queryParams.get('lessonId'));
  const { slug } = useParams(); // Accessing slug parameter from URL
  const { state } = useContext(Context);
  const { user } = state;
  const [expanded, setExpanded] = useState(false);
  const [langSupported, setLangSupported] = useState("");

  const [signedUrl, setSignedUrl] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  const [lesson, setLesson] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [enrolled, setEnrolled] = useState(false);
  const [course, setCourse] = useState(null);
  const [topics, setTopics] = useState([]);
  const [openTopicId, setOpenTopicId] = useState(null); // State to track which topic is open
  const [tests, setTests] = useState([]);
  const [showTestList, setShowTestList] = useState(false);
  const [showHTMLContent, setShowHTMLContent] = useState(false);

  const [selectedTest, setSelectedTest] = useState(null);
  const [topicId, setTopicId] = useState(''); // Set your topicId here
  const [activeLesson, setActiveLesson] = useState(null);
  const [activeItem, setActiveItem] = useState(null); // Initially, no item is active
  const [showCommentBox, setShowCommentBox] = useState(false)
  const [code, setCode] = useState(null);
  const [systemCode, setSystemCode] = useState(null);
  const [htmlContent, setHtmlContent] = useState(null)
  const [isCollapsed, setIsCollapsed] = useState(false); // New state for collapsible content

  const fetchTests = async () => {
    try {
      const userId = user.externalId;
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests`, {
        params: { topicId: openTopicId, userId },
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        },
      });
      setTests(response.data.responseData || []);
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };
  const checkEnrollment = async () => {
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/user/${user.externalId}/courses/${course.externalId}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            "Content-Type": "application/json",
          }
        }
      );

      setEnrolled(data.responseData);
    } catch (err) {
      console.log(err);
      setEnrolled(false);
    }
  };
  useEffect(() => {

    if (slug) {
      loadCourse(lessonId);
    }
  }, [slug]);

  useEffect(() => {
    if (course) {
      checkEnrollment();
    }
  }, [course]);

  const toggleContentCollapse = () => {
    setIsCollapsed(!isCollapsed); // Toggle the collapse state
  };





  const handleTestClick = () => {
    setSignedUrl(null);
    setCode(null)
    setSystemCode(null)
    setShowTestList(true); // Show the list of tests
    setSelectedTest(null);
    setActiveItem('tests' + '-' + openTopicId); // Set the active item to 'tests'
    setActiveLesson(null)
    fetchTests();
    setShowCommentBox(false)
    setShowHTMLContent(false)
    setHtmlContent(null)

  };


  const handleTestSelect = (test) => {
    setCode(null)
    setSystemCode(null)
    setSelectedTest(test);
    setShowTestList(false); // Hide the list of tests
    setSignedUrl(null);
    setShowCommentBox(false)
    setShowHTMLContent(false)
    setHtmlContent(null)

  };



  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const loadCourse = async () => {
    setLoadingPage(true)
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${slug}`, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      setCourse(data.responseData);
      loadLessons(data.responseData.externalId);
    } catch (error) {
      console.error("Error loading course:", error);
    }

  };

  const loadLessons = async (externalId) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${externalId}/topics/list`,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        }
      );
      const topicList = convertTopics(data.responseData);
      setTopics(topicList);
      if (topicList && topicList.length > 0 && topicList[0].lessons.length > 0) {
        const result = getTopicIdByLessonId(topicList, lessonId)
        setOpenTopicId(result ? result.topicId : topicList[0].topicId)
        setExpanded(result ? result.topicId : topicList[0].topicId);
        handleClick(result ? result.lesson : topicList[0].lessons[0]);
      }

    } catch (error) {
      console.error("Error loading lessons:", error);
    } finally {
      setLoadingPage(false)
    }
  };

  const getTopicIdByLessonId = (topicList, lessonId) => {
    for (let topic of topicList) {
      const matchingLesson = topic.lessons.find(lesson => lesson.externalId === lessonId);

      if (matchingLesson) {
        return {
          topicId: topic.topicId,
          lesson: matchingLesson
        }
      }
      // Return null if not found
    }
    return null;
  };

  // Function to handle lesson click
  const handleClick = async (lesson) => {
    setShowTestList(false);
    setActiveItem(null); // Set the active item to 'tests'
    setSelectedTest(null)
    setLesson(lesson);
    setLessonId(lesson.externalId)
    fetchSignedUrl(lesson);
    setActiveLesson(lesson.externalId);

  };


  // Function to fetch signed URL for PDF
  const fetchSignedUrl = async (lesson) => {
    setLoadingContent(true);
    if (!lesson || !lesson.pdfUrl) {
      if (lesson.description && lesson.description.length > 5) {
        await getHtmlContent();
      }
      return;
    }


    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/documents/signed-url`, {
        params: {
          documentKey: lesson.pdfUrl,
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      setSignedUrl(data.responseData);
      setShowHTMLContent(false)
      setHtmlContent(null)
      setCode(null)
    } catch (error) {
      console.error("Error fetching signed URL:", error);
    } finally {
      setLoadingContent(false);
    }

    async function getHtmlContent() {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/courseId/topics/${lesson.externalId}/content`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        });
        let html = DOMPurify.sanitize(data.responseData.content, {
          ADD_TAGS: ['iframe'], // Allow iframe tag
          ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'src'], // Allow iframe attributes
          FORBID_ATTR: ['srcdoc'], // Forbid potentially dangerous attributes
          ALLOW_URI_REGEXP: /^https:\/\/www\.youtube\.com\/embed\// // Allow only YouTube iframe src links
        });
        setHtmlContent(html);
        setCode(data.responseData.code);
        setSystemCode(data.responseData.systemCode);
        setLangSupported(data.responseData.langSupported);
      } catch (error) {
        console.error("Error fetching signed URL:", error);
      } finally {
        setLoadingContent(false);
      }
      setSignedUrl(null);
      setShowHTMLContent(true);
    }
  };

  const handleZoomClick = (imgSrc) => {
    setCurrentImageSrc(imgSrc);
    setIsImageModalOpen(true);
  };

  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setOpenTopicId(panel)
  };



  // Function to convert API response to topics with lessons format
  const convertTopics = (response) => {
    if (!response || !Array.isArray(response)) {
      return [];
    }

    const topicsArray = response.reduce((acc, topic) => {

      const existingTopic = acc.find((t) => t.topicSlug === topic.topicSlug);

      if (existingTopic) {
        existingTopic.lessons.push({
          externalId: topic.externalId,
          rank: topic.rank,
          description: topic.description,
          videoUrl: topic.videoUrl,
          pdfUrl: topic.pdfUrl,
          name: topic.name,
          paid: topic.paid,
          codeEditor: topic.codeEditor,

        });
        if (topic.testAvailable) {
          existingTopic.topicTest = topic.testAvailable
        }

      } else {
        acc.push({
          topicName: topic.topicName,
          topicSlug: topic.topicSlug,
          topicId: topic.topicId,
          topicTest: topic.testAvailable,
          lessons: [
            {
              externalId: topic.externalId,
              rank: topic.rank,
              description: topic.description,
              videoUrl: topic.videoUrl,
              pdfUrl: topic.pdfUrl,
              name: topic.name,
              paid: topic.paid,
              codeEditor: topic.codeEditor

            },
          ],
        });
      }

      return acc;
    }, []);

    return topicsArray;
  };

  const preContentMap = new Map();

  const processHtmlContent = (htmlContent, langSupported) => {
    let preCounter = 0; // Counter to give each <pre> a unique ID
    // Regular expression to find all <pre> tags
    let imgC = 0; // Counter to give each <img> a unique ID
    const preRegex = /<pre[^>]*>([\s\S]*?)<\/pre>/g;
    const imgRegex = /<img[^>]+src="([^">]+)"[^>]*>/g;

    // Function to replace <pre> with custom div structure
    let modifiedHtml = htmlContent.replace(preRegex, (match, codeContent) => {
      preCounter += 1; // Increment counter for each <pre> found
      const uniqueKey = `pre_${preCounter}`;

      // Create a temporary div to parse the HTML and extract innerText
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = codeContent;
      let sanitizedCodeContent = tempDiv.innerText.trim(); // Get only the text content

      // Split lines to calculate minimum indentation and clean up extra blank lines
      let lines = sanitizedCodeContent.split('\n');

      // Remove extra indentation by calculating the minimum indentation
      const minIndent = Math.min(
        ...lines.filter(line => line.trim()).map(line => line.match(/^\s*/)[0].length)
      );
      lines = lines.map(line => line.slice(minIndent));

      // Remove excess blank lines (more than 3 consecutive)
      const maxBlankLines = 3;
      let blankLineCount = 0;
      lines = lines.filter(line => {
        if (line.trim() === '') {
          blankLineCount += 1;
          return blankLineCount <= maxBlankLines;
        } else {
          blankLineCount = 0; // Reset counter when a non-blank line is encountered
          return true;
        }
      });

      // Join lines back into the sanitized content
      sanitizedCodeContent = lines.join('\n');


      var codeCopy = sanitizedCodeContent.replace(/\u00A0/g, ''); // Remove non-breaking spaces (0xA0)
      const highlightedCode = hljs.highlight(sanitizedCodeContent, { language: langSupported }).value;

      // Store the innerText (plain code) in the map with a unique key
      preContentMap.set(uniqueKey, codeCopy);

      // Return custom HTML structure, including "Copy Code" button with a unique data attribute
      return `
      <div class="code-editor-container" style="position: relative; padding-top: 10px; border: 1px solid #ddd; border-radius: 8px; background:#9ec5b4; max-width: 70%;">
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 8px;">
          <span style="font-size: 14px; font-weight: bold; color: #333; padding-left: 6px;">${langSupported.toUpperCase()}</span>
          <button class="copy-code-btn" style="padding: 4px 4px; font-size: 10px; cursor: pointer; border-radius: 6px; margin-right: 10px;" data-key="${uniqueKey}">Copy Code</button>
        </div>
        <pre class="ql-syntax"><code class="hljs">${highlightedCode}</code></pre>
      </div>
    `;
    });

    modifiedHtml = modifiedHtml.replace(imgRegex, (match, imgSrc) => {
      imgC += 1;
      const uniqueKey = `img_${imgC}`;
  
      return `
        <div style="position: relative;  margin: 10px;">
          <img src="${imgSrc}" alt="Image ${imgC}" data-key="${uniqueKey}" />
          <button 
            class="zoom-image-btn" 
            style="display: flex;  background:transparent ; border: none; cursor: pointer; justify-self: center; padding-top: 10px;" 
            data-key="${uniqueKey}" 
            >🔍(zoom+)
          </button>
        </div>
      `;
    });

    return modifiedHtml;
  };




  // Function to show the notification message
  function showCopyNotification(message) {
    // Create a notification element
    const notification = document.createElement('div');
    notification.innerText = message;
    notification.style.position = 'fixed';
    notification.style.bottom = '20px';
    notification.style.right = '20px';
    notification.style.padding = '10px 20px';
    notification.style.backgroundColor = '#28a745';
    notification.style.color = 'white';
    notification.style.borderRadius = '5px';
    notification.style.fontSize = '14px';
    notification.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.1)';
    notification.style.zIndex = '1000';
    notification.style.opacity = '1';
    notification.style.transition = 'opacity 0.5s ease';

    // Append the notification to the body
    document.body.appendChild(notification);

    // Remove the notification after 1 second
    setTimeout(() => {
      notification.style.opacity = '0';
      setTimeout(() => {
        notification.remove(); // Remove the element from the DOM
      }, 500); // Wait for the transition to complete
    }, 1000); // Show the notification for 1 second
  }

  const handleCloseModal = () => {
    setIsImageModalOpen(false);
    setCurrentImageSrc('');
  };

  useEffect(() => {
    const handleClick = (event) => {
      // Handle Copy Code Button Click
      if (event.target.classList.contains('copy-code-btn')) {
        const preKey = event.target.getAttribute('data-key');
        const codeContent = preContentMap.get(preKey);
  
        if (codeContent) {
          navigator.clipboard.writeText(codeContent)
            .then(() => {
              console.log('Code copied to clipboard!');
              showCopyNotification('Code copied!');
            })
            .catch(err => {
              console.error('Failed to copy code:', err);
              showCopyNotification('Failed to copy code.');
            });
        } else {
          console.error('No code found for the key:', preKey);
        }
      }
  
      // Handle Zoom Image Button Click
      if (event.target.classList.contains('zoom-image-btn')) {
        const img = event.target.previousElementSibling; // Assuming <img> is right before the button
        if (img) {
          handleZoomClick(img.src);
        }
      }
    };
  
    // Attach the click event listener to the document
    document.addEventListener('click', handleClick);
  
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ [htmlContent, lesson]]);

  useEffect(() => {
    if (htmlContent) {
      // Re-process content to ensure preContentMap is updated
      processHtmlContent(htmlContent, langSupported);
    }
  }, [htmlContent, langSupported]);

  
  return (
    <div className="container-fluid">
      {loadingPage && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {!loadingPage && <div className="row">
        {collapsed && (
          <div className={`col-md-3 sidebar ${collapsed ? "open" : "closed"}`}>
            <div className="sidebar-content">
              {topics && topics.length > 0 && topics.map((topic) => (
                <Accordion className='accord'
                  key={topic.topicId}
                  expanded={expanded === topic.topicId}
                  onChange={handleChange(topic.topicId)}
                  style={{ maxWidth: '100%' }}
                >
                  <AccordionSummary
                    aria-controls={`panel${topic.topicId}-content`}
                    id={`panel${topic.topicId}-header`}
                    style={{
                      minHeight: '30px',
                      height: '60px',
                      color: '#fff',
                      background: 'rgb(205 221 219 / 40%)' /* Sidebar background */
                    }}
                  >
                    <Typography style={{ flexGrow: 1, paddingTop: '8px', paddingLeft: '6px', color: "black", fontSize: '0.95rem' }}>{topic.topicName}</Typography>
                    <Button
                      variant="link"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChange(topic.topicId);

                      }}>
                      {expanded === topic.topicId ? (
                        <i className="bi bi-chevron-up arrowUp" style={{ cursor: 'pointer', color: "black", paddingRight: '0px' }}></i>
                      ) : (
                        <i className="bi bi-chevron-down arrowDown" style={{ cursor: 'pointer', color: "black", paddingRight: '0px' }}></i>
                      )}
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails style={{
                    backgroundColor: 'white',
                    minHeight: '50px',
                    paddingLeft: '0px',
                    paddingRight: '0px'
                  }}>
                    <List component="nav" aria-label="lessons">
                      {topic.lessons && topic.lessons.length > 0 && topic.lessons.map((lesson, index) => (
                        <div key={lesson.externalId}>
                          <ListItem
                            button
                            onClick={() => handleClick(lesson)}
                            selected={activeLesson === lesson.externalId} // Add selected prop for active state
                            style={{
                              backgroundColor: activeLesson === lesson.externalId ? 'rgb(184 197 209)' : 'inherit', // Active background color
                            }}
                          >
                            <ListItemText primary={lesson.name} />
                          </ListItem>
                          {index !== topic.lessons.length - 1 && <Divider />}
                        </div>
                      ))}
                      {topic.topicTest ? <ListItem
                        button
                        onClick={() => handleTestClick()}
                        selected={activeItem === 'tests' + '-' + openTopicId}
                        style={{
                          backgroundColor: activeItem === 'tests' + '-' + openTopicId ? 'rgb(184 197 209)' : 'inherit', // Active background color
                        }}
                      >
                        <ListItemText primary="Quiz" />
                      </ListItem> : null}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        )}

        <div className="col-md-9 Test">
          {!enrolled && lesson && lesson.paid ? (
            <div className="lock-overlay">
              <LockIcon className="lock-icon" fontSize="large" />
              <div className="lock-message">This lesson is locked. Please enroll to access</div>
            </div>
          ) : (
            <div className={`custom-content ${!code ? 'full-layout' : (isCollapsed ? 'collapsed-layout' : 'split-layout')}`}>

              {lesson && signedUrl && <PdfViewer pdfUrl={signedUrl} topicName={lesson.name} />}

              {showTestList && (
                <Test tests={tests} onTestSelect={handleTestSelect} isDisabled={true} />
              )}

              {selectedTest && (
                <TestModal
                  showTest={true}
                  setShowTest={() => setSelectedTest(null)}
                  test={selectedTest}
                  user={user}
                  setShowTestList={setShowTestList}
                  courseCode={slug}
                />
              )}

              {loadingContent && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                  <CircularProgress color="primary" />
                </div>
              )}

              {showHTMLContent && (
                <>
                  <div
                    className="html-content"
                    dangerouslySetInnerHTML={{ __html: processHtmlContent(htmlContent, langSupported) }}
                  >
                  </div>
                <ImageModal open={isImageModalOpen} handleClose={handleCloseModal} imgSrc={currentImageSrc}/>
                  {lesson && lesson.codeEditor && code && (
                    <div className={`code-editor ${isCollapsed ? 'full-layout' : ''}`}>
                     
                      <CodeEditor
                        questionId={lesson.externalId}
                        user={user}
                        initialCode={code}
                        systemCode={systemCode}
                        langSupport={langSupported}
                        toggleContentCollapse={toggleContentCollapse}
                        isCollapsed={isCollapsed}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>


      </div>}
    </div>
  );
};

export default UserCourse;
