import Grid from '@mui/material/Grid';
import TestCard from './cards/TestCard'; // Adjust import based on your project structure

const Test = ({ tests, onTestSelect,isDisabled }) => {
  // We don't need to slice the array here; Grid will handle item layout
  return (
    <div>
      <Grid container spacing={3} sx={{ paddingTop: '20px', paddingLeft: '16px' }}>
        {tests.map((test, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <TestCard test={test} handleViewTest={() => onTestSelect(test)}  isDisabled={isDisabled && test.attempts > 2}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Test;
