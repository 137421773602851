import logo from './logo.svg';
import './App.css';
import  TopNav from "./component/TopNav";
import { ToastContainer } from "react-toastify";
import {Provider  } from "./context/index";
import { BrowserRouter as Router } from 'react-router-dom';
import MyRoutes from './Route';
import Footer from './component/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';


function App()  {
 

  return (
     <Provider >
       <Router>
       <div className="app-container"> 
          <TopNav />
          <MyRoutes />
          <Footer />
        </div>
       </Router>
     </Provider>
  )

}

export default App;
