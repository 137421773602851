// TestList.js
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import TestCard from '../../../../component/cards/TestCard';
import AddTestModal from '../../../../component/modal/AddTestModal';
import { IconButton, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import axios from 'axios';
import { redirectTo } from '../../../../component/Redirect';
import { Context } from '../../../../context';

const TestIndex = () => {
  const [tests, setTests] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const {state} = useContext(Context)
  const {user} = state;
  const handleViewTest = (testId) => {
    // Replace with navigation or modal opening logic
    console.log(`View test with ID: ${testId}`);
    redirectTo(`/instructor/course/test/${testId}`);
  };


  useEffect(() => {
    // Replace the URL with your actual API endpoint
    const fetchTests = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests`,{
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        });
        setTests(response.data.responseData);
      } catch (error) {
        console.error('Error fetching tests:', error);
      }
    };

    fetchTests();
  }, []);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <div>
      <Tooltip title="Add New Test">
        <IconButton
          onClick={handleOpenModal}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
        >
          <Add />
        </IconButton>
      </Tooltip>
      <Grid container spacing={2} sx={{ paddingTop: '20px', paddingLeft: '20px' }}>
        {tests.map((test, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <TestCard test={test} handleViewTest={handleViewTest}  isDisabled={test.retries >= 3} />
          </Grid>
        ))}
      </Grid>
      <AddTestModal
        open={modalOpen}
        onClose={handleCloseModal}
        user={user}
      />
    </div>
  );
};

export default TestIndex;
