import React, { useEffect, useState } from "react";
import axios from "axios";
import Post from "../../component/Post";
import  "../../css/blog.css";

const BlogIndex = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        loadPosts();
    }, []);

    const loadPosts = async () => {
        try {
            let { data } = await axios.get(process.env.REACT_APP_BASE_URL + `/v1/posts`);
            if (data.responseData) {
                setPosts(data.responseData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="blog-index-container">
            <div className="posts-grid">
                {posts && posts.length > 0 && posts.map((post, id) => (
                    <div key={id} className="post-item">
                        <Post
                            title={post.title}
                            cover={post.coverImageUrl}
                            summary={post.summary}
                            createdBy={post.createdBy}
                            createdAt={post.createdAt}
                            slug={post.titleSlug}
                            content={post.content}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogIndex;
