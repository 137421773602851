import React from "react";
import "../css/DesignTable.css";

export default function DesignTable({level,totalLessons}) {
    return (
        <div className="table-container">
            <table className="custom-table">
                <tbody>
                    <tr>
                        <td><strong>{totalLessons}</strong><br/><span style={{fontWeight:300,fontSize:'14px'}}>Modules</span></td>
                        <td><strong>{level.toUpperCase()}</strong><br/><span style={{fontWeight:300,fontSize:'14px'}}>Level</span></td>
                        <td><strong>Flexible</strong><br/><span style={{fontWeight:300, fontSize:'14px'}}>Schedule</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
