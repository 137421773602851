import { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import '../../css/dashboard-sidebar.css';
const UserNav = () => {
  const [current, setCurrent] = useState("");

  const {state} = useContext(Context);
  const {user}= state;

  useEffect(() => {
    setCurrent(window.location.pathname);
  }, []);

  return (
    <div className="nav flex-column nav-pills" >
      <a href="/user" className={`nav-link ${current === "/user" && "active"}`} 
        style={{paddingLeft:'20%', border: '1px solid #dbdbdb',textAlign:'left',borderRadius:'0px' , fontSize:'20px',   fontFamily: 'auto'}}>
        Courses
      </a>
      <a href="/user/performance" className={`nav-link ${current === "/user/performance" && "active"}`} 
        style={{ paddingLeft:'20%',border: '1px solid #dbdbdb',textAlign:'left',borderRadius:'0px',  fontSize:'20px', fontFamily: 'auto' }}>
        Performance
      </a>
      {user && user.reward != null && user.reward && 
        <a href="/user/rewards" className={`nav-link ${current === "/user/rewards" && "active"}`} 
        style={{paddingLeft:'20%', border: '1px solid #dbdbdb',textAlign:'left',borderRadius:'0px' , fontSize:'20px', fontFamily: 'auto'}}>
          Rewards
        </a>
      }
      <a href="/user/profile" className={`nav-link ${current === "/user/profile" && "active"}`} 
          style={{paddingLeft:'20%', border: '1px solid #dbdbdb',textAlign:'left',borderRadius:'0px',  fontSize:'20px', fontFamily: 'auto' }}>
        Profile
      </a>
</div>

  );
};

export default UserNav;
