import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../context";
import { Container, Grid, Card, CardContent, Typography, CardMedia, Button } from '@mui/material';
import { Link } from "react-router-dom";
import axios from "axios";
import { redirectTo } from "../../component/Redirect";

const InstructorIndex = () => {
  const { state } = useContext(Context);
  const { user } = state;

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if(user)loadCourses();
  }, [user]);

  const loadCourses = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/instructor/list`,{
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      setCourses(data.responseData);
    } catch (error) {
      console.error("Error loading courses:", error);
    }
  };

  const handlePublish = async (courseId) => {
    try {
      let { data } = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/publish`,null,{
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        });
      alert(`Course published`);
      window.location.reload();
    } catch (error) {
      console.error("There was an error publishing the course!", error);
      alert('Error publishing the course');
    }
  };

  return (
    <div >
      <h1 className="inst-dashboard-jumbotron text-center square">Instructor Dashboard</h1>
      <Container style={{marginLeft: '0'}}>
        <Grid container spacing={3}>
          {courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} key={course.name}>
              <Link to={`/instructor/course/view/${course.name}`} style={{ textDecoration: 'none' }}>
                <Card sx={{ width: '70%', margin: 'auto', height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={course.url}
                    alt={course.name}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      {course.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Price: ₹{course.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {course.level}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePublish(course.externalId);
                      }}
                      sx={{ mt: 2 }}
                      disabled={course.status === 'published'}
                     >
                      Publish
                    </Button>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default InstructorIndex;
