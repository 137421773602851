import React, { useState, useEffect, useRef, Suspense, useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
    Button,
    Card,
    CardContent,
    Typography,
    Box,
    Collapse,
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import axios from 'axios';
import SafeHTML from '../../../../component/SafeHtml';
import { Context } from '../../../../context';
import 'react-quill/dist/quill.snow.css';
import 'highlight.js/styles/default.css';

import { Select, MenuItem } from "@mui/material";
import CodeEditorModal
 from '../../../../component/modal/CodeEditorModal';
// Highlight.js languages
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import python from 'highlight.js/lib/languages/python';
import go from 'highlight.js/lib/languages/go';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';

// Custom CSS for code blocks
const customStyles = `
  .ql-editor pre {
    max-width: 50%;
    margin: 0 auto;
    overflow-x: auto;
  }
`;

// Lazy load ReactQuill component
const ReactQuill = React.lazy(() => import('react-quill'));

// Register the languages
hljs.registerLanguage('java', java);
hljs.registerLanguage('python', python);
hljs.registerLanguage('go', go);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('html', xml);
hljs.registerLanguage('css', xml);

const modules = {
    syntax: {
        highlight: text => hljs.highlightAuto(text).value,
    },
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['code-block'],
        ['clean']
    ]
};

const SlugBasedTest = () => {
    const [testDetails, setTestDetails] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [newQuestion, setNewQuestion] = useState('');
    const [type, setType] = useState(null);
    const [difficulty, setDifficulty] = useState(null);
    const [showCodeEditorModal, setShowCodeEditorModal] = useState(false); // State to handle code editor modal
    const[questionId,setQuestionId]= useState('');
    const [newAnswer, setNewAnswer] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newOptions, setNewOptions] = useState([{ optionId: 1, text: '', correct: false }]);
    const [quillKey, setQuillKey] = useState(Date.now());
    const { state } = useContext(Context)
    const { user } = state;
    const { slug } = useParams(); // Assume 'slug' is the externalId

    // Fetch test details and questions
    useEffect(() => {
        // Fetch test details
        const fetchTestDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests`, {
                    params: {
                        testId: slug
                    },
                    headers: {
                        Authorization: "Bearer " + user.accessToken,
                        "Content-Type": "application/json",
                    }
                });
                if (response.data.responseData && response.data.responseData.length > 0) {
                    setTestDetails(response.data.responseData[0]);
                }
            } catch (error) {
                console.error('Error fetching tests:', error);
            }
        };

        //Fetch questions
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests/${slug}/questions`, {
                    params: {
                        page: 1,
                        size: 40
                    },
                    headers: {
                        Authorization: "Bearer " + user.accessToken,
                        "Content-Type": "application/json",
                    }
                });
                if (response.data.responseData && response.data.responseData.length > 0) {
                    setQuestions(response.data.responseData);
                }
            } catch (error) {
                console.error('Error fetching tests:', error);
            }
        };

        fetchTestDetails();
        fetchQuestions();
    }, [slug]);

    // Handle expanding/collapsing questions
    const handleToggleExpand = (id) => {
        setExpanded(expanded === id ? null : id);
    };

    // Handle deleting a question
    const handleDeleteQuestion = (id) => {
        setQuestions(questions.filter(question => question.id !== id));
    };

    // Handle adding a new question
    const handleAddQuestion = async () => {
        if (!newQuestion.trim() || !newAnswer.trim()) {
            alert('Please fill out both the question and answer fields.');
            return;
        }

        const newId = questions.length + 1;
        const newQuestionData = {
            id: newId,
            description: newQuestion,
            answerDescription: newAnswer,
            type: type,
            difficulty: difficulty,
            options: newOptions.map((opt, index) => ({
                ...opt,
                optionId: index + 1 // Ensure unique optionId

            })),
        };


        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v1/tests/${slug}/questions`,
                newQuestionData, {
                headers: {
                    Authorization: "Bearer " + user.accessToken,
                    "Content-Type": "application/json",
                }
            }
            );

        } catch (error) {
            console.error('Error Creating tests', error);
        } finally {
        }


        setQuestions([...questions, newQuestionData]);

        // Reset the form fields
        setNewQuestion('');
        setNewAnswer('');
        setNewDescription('');
        setNewOptions([{ optionId: 1, text: '', correct: false }]);
    };

    // Handle option changes
    const handleOptionChange = (index, key, value) => {
        const updatedOptions = [...newOptions];
        updatedOptions[index][key] = value;
        setNewOptions(updatedOptions);
    };

    // Add a new option
    const addOption = () => {
        setNewOptions([...newOptions, { optionId: newOptions.length + 1, text: '', correct: false }]);
    };

    // Remove an option
    const removeOption = (index) => {
        setNewOptions(newOptions.filter((_, i) => i !== index));
    };

    const handleCodeEditorClick = (id) => {
        setQuestionId(id);

        setShowCodeEditorModal(true); // Show the modal
      };

    // Function to handle saving the code description and test cases
    const handleSaveCodeData = async (questionId, codeData) => {
        try {
            let { data } = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v1/testcases/entity`,
                codeData, {
                headers: {
                    Authorization: "Bearer " + user.accessToken,
                    "Content-Type": "application/json",
                }
            }
            );
            console.log("Test case Created", data);
            alert("added");
        } catch (err) {
            console.error("Error creating course", err);
        }

    };

    // // Submit data
    // const submitData = async () => {
    //     const payload = {
    //         name: testDetails?.name || 'Sample Test',
    //         description: testDetails?.description || 'No description',
    //         timeLimit: testDetails?.timeLimit || 60,
    //         questions,
    //     };
    //     console.log('Submitting data:', payload);

    //     try {
    //         const response = await fetch('/api/submit', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(payload),
    //         });
    //         const result = await response.json();
    //         console.log('Success:', result);
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    return (
        <div style={{ paddingLeft: '14px', paddingRight: '8px' }}>
            {testDetails && (
                <>
                    <Typography variant="h5" align='center' gutterBottom>
                        {testDetails.testName}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {testDetails.testDescription}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Time Limit: {testDetails.testDuration} minutes
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Number of Questions: {testDetails.numQuestions}
                    </Typography>
                </>
            )}

            <div className="form-group">
                <Select
                    style={{ width: "100%" }}
                    value={type}
                    onChange={(v) => setType(v.target.value)}
                >
                    <MenuItem value='MCQ'>MCQ</MenuItem>
                    <MenuItem value='CODE'>CODING</MenuItem>
                </Select>
            </div>
            <div className="form-group">
                <Select
                    style={{ width: "100%" }}
                    value={difficulty}
                    onChange={(v) => setDifficulty(v.target.value)}
                >
                    <MenuItem value='EASY'>EASY</MenuItem>
                    <MenuItem value='MEDIUM'>MEDIUM</MenuItem>
                    <MenuItem value='HARD'>HARD</MenuItem>

                </Select>
            </div>
            <Box sx={{ mb: 2, borderRadius: '8px', p: 1, border: '2px solid #ccc' }}>
                <Typography variant="h6">Add New Question</Typography>
                <div className="form-group">
                    <Suspense fallback={<div>Loading editor...</div>}>
                        <ReactQuill
                            key={quillKey}
                            modules={modules}
                            placeholder="Question..."
                            style={{ height: '100px' }}
                            onChange={setNewQuestion}
                        />
                    </Suspense>
                </div>
                <br />
                <div className="form-group">
                    <Suspense fallback={<div>Loading editor...</div>}>
                        <ReactQuill
                            key={quillKey}
                            modules={modules}
                            placeholder="Answer..."
                            style={{ height: '100px' }}
                            onChange={setNewAnswer}
                        />
                    </Suspense>
                </div>
                <br />
                {newOptions.map((option, index) => (
                    <Box key={option.optionId} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Suspense fallback={<div>Loading editor...</div>}>
                            <ReactQuill
                                key={option.optionId}
                                modules={modules}
                                value={option.description}
                                onChange={(description) => handleOptionChange(index, 'description', description)}
                                placeholder={`Option ${index + 1}`}
                                style={{ flex: 1, minHeight: '60px', marginRight: '1rem' }}
                            />
                        </Suspense>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={option.correct}
                                    onChange={(e) => handleOptionChange(index, 'correct', e.target.checked)}
                                />
                            }
                            label="Correct"
                        />
                        <IconButton onClick={() => removeOption(index)} color="error">
                            ❌
                        </IconButton>
                    </Box>
                ))}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Button
                        variant="contained"
                        onClick={addOption}
                        sx={{ mb: 2 }}
                    >
                        Add Option
                    </Button>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddQuestion}
                >
                    Add Question
                </Button>
            </Box>
            <Box sx={{ maxHeight: 500, overflowY: 'auto' }}>
                {questions.map((question) => (
                    <Card key={question.externalId} sx={{
                        mb: 2,
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        position: 'relative',
                        maxHeight: 300,
                        overflow: 'auto'
                    }} >
                        <CardContent
                            sx={{
                                p: 1.5,
                                '&:last-child': {
                                    pb: 1.5,
                                },
                            }}>
                            <Typography variant="h7" component="div">
                                <div dangerouslySetInnerHTML={{ __html: question.description }} />
                            </Typography>
                            <IconButton
                                onClick={() => handleToggleExpand(question.externalId)}
                                aria-expanded={expanded === question.externalId}
                                aria-label="show more"
                                sx={{ position: 'absolute', right: 16, top: 16 }}
                            >
                                {expanded === question.externalId ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <Collapse in={expanded === question.externalId}>
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="h8" component="div">
                                        Question Type:{question.type}
                                    </Typography>
                                    <Typography variant="h8" component="div">
                                        Level:{question.difficulty}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Answer:   <div dangerouslySetInnerHTML={{ __html: question.answerDescription }} />
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Options:
                                    </Typography>
                                    <ul style={{ padding: 0, listStyleType: 'none' }}>
                                        {question.options.map((option) => (
                                            <li
                                                key={option.optionId}
                                                style={{
                                                    marginBottom: '10px', // Add some spacing
                                                    padding: '5px 0' // Padding for better spacing
                                                }}
                                            >
                                                <strong style={{ marginRight: '10px' }}>
                                                    {option.optionId}:{option.correct && (
                                                        <span style={{ color: 'green', marginLeft: '10px' }}>(Correct)</span>
                                                    )}
                                                </strong>
                                                <SafeHTML html={option.optionDescription} />

                                            </li>
                                        ))}
                                    </ul>
                                    {"CODE"===question.type && (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCodeEditorClick(question.externalId);
                                            }}
                                        >
                                            Add Code
                                        </Button>
                                    )}
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => handleDeleteQuestion(question.externalId)}
                                    >
                                        Delete Question
                                    </Button>

                                </Box>
                            </Collapse>
                        </CardContent>
                    </Card>
                ))}
                {showCodeEditorModal && (
                    <CodeEditorModal
                        showModal={showCodeEditorModal}
                        setShowModal={setShowCodeEditorModal}
                        codeQuestionId={questionId}
                        onSave={handleSaveCodeData}
                    />
                )}
            </Box>
        </div>
    );
};

export default SlugBasedTest;
