import React, { useState, useEffect, useContext } from 'react';
import {
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Grid,
    Box,
    Paper,
    Container,
    Chip
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Context } from '../../../context';

const SafeHTML = ({ html }) => {
    return <div style={{ paddingTop: '4px' }} dangerouslySetInnerHTML={{ __html: html }} />;
};

const TestView = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userResponse, setUserResponse] = useState({});
    const { state } = useContext(Context);
    const { user } = state;
    const [test, setTest] = useState({});
    const [userAnswers, setUserAnswers] = useState({});


    const { slug } = useParams();

    useEffect(() => {
        if (slug && user) {
            fetchTest(slug);
            fetUserResult(slug);
        }
    }, [slug, user]);

    const fetchTest = async (testId) => {
        try {
            const userId = user.externalId;
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests`, {
                params: { testId, userId }
                ,
                headers: {
                    Authorization: "Bearer " + user.accessToken,
                    "Content-Type": "application/json",
                }
            });
            setTest(response.data.responseData[0] || null);
        } catch (error) {
            console.error('Error fetching tests:', error);
        }
    };

    const fetUserResult = async (testId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/tests/${testId}/questions/${user.externalId}`, {
                params: {
                    page: 1,
                    size: 40
                },
                headers: {
                    Authorization: "Bearer " + user.accessToken,
                    "Content-Type": "application/json",
                }
            });
            if (response.data.responseData) {
                setUserResponse(response.data.responseData);
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex < userResponse.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    // Check if there are questions and if the current question exists
    const currentQuestion = userResponse ? userResponse.questions ? userResponse.questions[currentQuestionIndex] || {} : {} : {};
    return (
        <div style={{ marginTop: '30px', display: 'flex' }}>
            {userResponse.questions &&
                <Container maxWidth="md"> {/* Adjust 'md' to your preferred size */}
                    {test &&
                        <h4 style={{ textAlign: "center" }}>
                            {test.testName || 'Test Name'} {/* Display the test name */}
                        </h4>
                    }
                    <div style={{ marginTop: '40px' }}></div>


                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box display="flex" gap={1}>
                                    {userResponse.questions.map((_, index) => {
                                        // Determine the chip color based on the question's correctness
                                        const isCorrect = userResponse.questions[index].correct; // assuming `correct` is a boolean property
                                        let chipColor;
                                        if (index === currentQuestionIndex) {
                                            chipColor = isCorrect ? 'success' : 'error'; // Highlight current question
                                        } else {
                                            chipColor = isCorrect === true
                                                ? 'success'
                                                : isCorrect === false
                                                    ? 'error'
                                                    : 'default';
                                        }
                                        const chipVariant = index === currentQuestionIndex ? 'filled' : 'outlined';
                                        return (
                                            <Chip
                                                key={index}
                                                label={index + 1}
                                                color={chipColor}
                                                onClick={() => setCurrentQuestionIndex(index)}
                                                variant={chipVariant}
                                            />
                                        );
                                    })}
                                </Box>
                                <Box>
                                    <Box>
                                        <Typography variant="h7" gutterBottom>
                                            User Marks: {userResponse.userMarks}/ {userResponse.totalMarks}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Typography variant="h7" gutterBottom>
                                            Total Correct:{userResponse.totalCorrectAnswered}/ {userResponse.totalQuestion}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <div style={{ marginTop: '40px' }}></div>

                    <Grid item xs={12} container justifyContent="center" spacing={2}>
                        <Grid item>
                            {currentQuestionIndex > 0 && (
                                <Button variant="outlined" onClick={handlePrevQuestion}>Previous Question</Button>
                            )}
                        </Grid>
                        <Grid item>
                            {currentQuestionIndex < userResponse.questions.length - 1 && (
                                <Button variant="outlined" onClick={handleNextQuestion}>Next Question</Button>
                            )}
                        </Grid>
                    </Grid>

                    <div style={{ marginTop: '40px' }}></div>

                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {`Q${currentQuestionIndex + 1}.`}<SafeHTML html={currentQuestion.description || ''} />
                        </Typography>
                    </Grid>
                    {currentQuestion.options && currentQuestion.options[0].optionDescription ? <Grid item xs={12}>
                        <Box p={2} component={Paper} elevation={3} sx={{ margin: 'auto' }}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {currentQuestion.options?.map((option, optionIndex) => (
                                        <FormControlLabel
                                            key={option.optionId}
                                            control={
                                                <Checkbox
                                                    checked={option.correct}
                                                    name={`question${currentQuestion.externalId}-answers`}
                                                    value={option.optionId}
                                                    sx={{
                                                        color: '#4caf50', // Check mark color
                                                        '&.Mui-checked': {
                                                            color: '#4caf50', // Check color when checked
                                                        },
                                                        '&.Mui-disabled': {
                                                            color: '#4caf50', // Check mark color for disabled
                                                        },
                                                        '&.Mui-checked.Mui-disabled': {
                                                            color: '#4caf50', // Check color when checked and disabled
                                                        }
                                                    }}
                                                    disabled
                                                />
                                            }
                                            label={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: '8px', fontWeight: 'bold' }}>
                                                        {option.optionId}.
                                                    </span>
                                                    <SafeHTML html={option.optionDescription} />
                                                </div>
                                            }
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>

                        </Box>
                    </Grid> : null}
                    <Grid item xs={12}>
                        <Box
                            p={3} // Add padding inside the container
                            style={{
                                border: '1px solid #e0e0e0', // Light border color
                                borderRadius: '8px', // Rounded corners
                                marginTop: '20px', // Spacing above the container
                                backgroundColor: '#f9f9f9', // Light background color
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                            }}
                        >
                            {/* Display Correct/Incorrect Message */}
                            <Typography
                                variant="body1"
                                color={currentQuestion.correct ? 'green' : 'red'}
                                style={{ marginBottom: '10px', fontWeight: 'bold' }} // Slightly larger text and bold
                            >
                                {currentQuestion.correct ? 'Correct Answer' : 'Incorrect Answer'}
                            </Typography>

                            {/* Display User's Answers or Code */}
                            {currentQuestion.type !== 'CODE' ? (
                                <Typography variant="body2">
                                    <strong>Your Answers:</strong>{' '}
                                    {currentQuestion.userAnswer.map(optionId =>
                                        currentQuestion.options.find(option => option.optionId === optionId)?.optionText
                                    ).join(', ')}
                                </Typography>
                            ) : (
                                <div className="result-code" style={{ marginTop: '10px' }}>
                                    <pre>
                                        {currentQuestion.defaultCode}
                                    </pre>
                                </div>
                            )}

                            {/* Display Explanation */}
                            <Box mt={3}>
                                <Typography variant="body1">
                                    <strong>Explanation:</strong>
                                </Typography>
                                <Box mt={1}>
                                    <SafeHTML html={currentQuestion.answerDescription} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Container>
            }
        </div>
    );
};

export default TestView;
