import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../context";
import axios from "axios";
import { Grid, Card, CardContent, Typography, CardMedia, Button, Box, TextField ,InputAdornment } from '@mui/material';
import { redirectTo } from "../../component/Redirect";
import SearchIcon from '@mui/icons-material/Search';

const UserIndex = () => {
    const { state } = useContext(Context);
    const { user } = state;

    const [courses, setCourses] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (user) loadCourses();
    }, [user]);

    useEffect(() => {
        if (searchQuery === "") {
            setFilteredCourses(courses);
        } else {
            setFilteredCourses(
                courses.filter(course =>
                    course.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }
    }, [searchQuery, courses]);

    const loadCourses = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/user/${user.externalId}/courses`, {
                headers: {
                    Authorization: "Bearer " + user.accessToken,
                    "Content-Type": "application/json",
                }
            });
            setCourses(data.responseData);
            setFilteredCourses(data.responseData); // Initialize filteredCourses with all courses
        } catch (error) {
            console.error("Error loading courses:", error);
        }
    };

    const handleCourseClick = (course) => {
        redirectTo(`/user/course/${course.name}`);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div>
            <div style={{
                backgroundColor: 'rgb(46 89 115)', padding: '26px', marginBottom: '20px', marginLeft: '-20px',height:'120px'
            }}>
                <h1 className="courses text-center" style={{ margin: 0, color: '#fff' }}> Courses </h1>
            </div>

            {/* Search Bar */}
            <Box className="userBox" sx={{ padding: '15px'}}>
      <TextField
        label="Search Courses"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" >
              <SearchIcon className="search"/>
            </InputAdornment>
          ),
        }}
      />
    </Box>
            {/* Scrollable Container for Courses */}
            <Box sx={{ paddingTop: '15px', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
    <Grid container spacing={2}> {/* Reduced spacing to bring cards closer */}
        {filteredCourses.map((course, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                <Card className="cardName"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: '0px solid #11367d', // Optional bold border
                        borderRadius: '8px', // Optional rounded corners
                        marginBottom: '12px', // Reduced margin-bottom for cards
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Light shadow for cards
                    }}
                >
                    <CardContent sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', padding: '8px' }}> {/* Reduced padding */}
                        <CardMedia
                            component="img"
                            height="140" // Adjust height as needed
                            image={course.url}
                            alt={course.name}
                        />
                        <Box sx={{ height: '70px', padding: '8px' }}> {/* Reduced padding here */}
                            <Typography variant="h7" component="div" sx={{ fontSize: '16px', fontWeight: '600' }}>
                                {course.name}
                            </Typography>
                        </Box>
   
                        <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="custom-button"
                                onClick={() => handleCourseClick(course)}
                                sx={{ background: '#11367d', padding: '8px 16px', fontSize: '14px' }} // Adjust padding here
                            >
                                Go to Course
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        ))}
    </Grid>
</Box>

        </div>
    );
};

export default UserIndex;
