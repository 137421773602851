// AppRoutes.js (or Routes.js)
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import About from './pages/About'; // Example: Replace with your actual components
import Contact from './pages/Contact'; // Example: Replace with your actual components
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import UserRoute from './component/routes/UserRoute';
import UserIndex from './pages/user';
import InstructorRoute from './component/routes/InstructorRoute';
import InstructorIndex from './pages/instructor';
import BlogIndex from './pages/blog';
import CreatePost from './pages/instructor/blog/create';
import PostPage from './pages/blog/[slug]';
import UserCourse from './pages/user/course/[slug]';
import StudentRoute from './component/routes/StudentRoute';
import SingleCourse from './pages/course/[slug]';
import CourseCreate from './pages/instructor/course/create';
import CourseView from './pages/instructor/course/view/[slug]';
import CourseEdit from './pages/instructor/course/edit/[slug]';
import TestIndex from './pages/instructor/course/test';
import SlugBasedTest from './pages/instructor/course/test/[slug]';
import UserTest from './pages/user/test';
import TestView from './pages/user/test/[slug]';
import TermsAndConditions from './pages/TermsAndConditions';
import BuyingPage from './pages/ordersummary';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import ThankYouPage from './pages/ThankYou';
import MyRewards from './pages/user/Reward';
import MyProfile from './pages/user/profile';
import VerifyPage from './pages/VerifyPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/terms' element={<TermsAndConditions />} />

      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/course/:slug' element={<SingleCourse />} />
      <Route path="/verify/:token" element={<VerifyPage />} />

      <Route path="/user" element={
        <UserRoute>
          <UserIndex />
        </UserRoute>
      } />
      <Route path="/instructor" element={
        <InstructorRoute>
          <InstructorIndex />
        </InstructorRoute>
      } />
      <Route path="/instructor/blog/create" element={
        <InstructorRoute>
          <CreatePost />
        </InstructorRoute>
      } />
       <Route path="/instructor/course/create" element={
        <InstructorRoute>
          <CourseCreate />
        </InstructorRoute>
      } />

      <Route path="/instructor/course/view/:slug" element={
        <InstructorRoute>
          <CourseView />
        </InstructorRoute>
      } />

<Route path="/instructor/course/edit/:slug" element={
        <InstructorRoute>
          <CourseEdit />
        </InstructorRoute>
      } />

    <Route path="/instructor/course/test" element={
        <InstructorRoute>
          <TestIndex />
        </InstructorRoute>
      } />

<Route path="/instructor/course/test/:slug" element={
        <InstructorRoute>
          <SlugBasedTest />
        </InstructorRoute>
      } />

       <Route path="/user/course/:slug" element={
        <StudentRoute>
          <UserCourse />
        </StudentRoute>
      } />
      
    
     <Route path="/user/course/buy/:courseName" element={
        <StudentRoute>
          <BuyingPage />
        </StudentRoute>
      } />
      <Route path="/user/performance" element={
        <UserRoute>
          <UserTest />
        </UserRoute>
      } />
      <Route path="/user/performance/:slug" element={
        <UserRoute>
          <TestView />
        </UserRoute>
      } />
       <Route path="/user/profile" element={
        <UserRoute>
          <MyProfile />
        </UserRoute>
      } />
      <Route path="/user/rewards" element={
        <UserRoute>
          <MyRewards />
        </UserRoute>
      } />
      <Route path="/user/payment-success/:orderId" element={
        <StudentRoute>
          <PaymentSuccessPage />
        </StudentRoute>
      } />
       <Route path="/user/thank-you" element={
        <StudentRoute>
          <ThankYouPage />
        </StudentRoute>
      } />
      <Route path='/blog' element={<BlogIndex />} />
      <Route path='/blog/:slug' element={<PostPage />} />

    </Routes>

  );
};

export default AppRoutes;
