import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { Context } from "../context";
import { redirectTo } from '../component/Redirect';

const Register = () => {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    const { state: { user }, dispatch } = useContext(Context);

    useEffect(() => {
        if (user !== null) {
            redirectTo("/");
        }
    }, [user]);

    const clearForm = () => {
        setName("");
        setUsername("");
        setEmail("");
        setPassword("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const uri = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SIGNUP_URL}`;
        const data = { name, username, email, password };

        try {
            const res = await axios.post(uri, data);
            toast.success("Registration Success. Please login");
            console.log("response from server", res.data);
            setLoading(false);
            redirectTo("/login");
        } catch (error) {
            console.log("Error registering", error);
            setLoading(false);
            clearForm();
            setErrorMsg(error.response.data.statusInfo.errorMessage || "Registration failed. Please try again.");
        }
    };

    return (
        <div className='container col-md-10 mt-5 border rounded p-4'>
            <div className="row">
                <div className='col-md-6 aboutLeft'>
                    <div className="pull-right">
                        <img src="https://course-image-data.s3.ap-south-1.amazonaws.com/signup.png" className='loginImageLeft' />
                    </div>
                </div>
                <div className='col-md-6 aboutRight' >
                    <div className="container col-md-8 pb-5 signUp">
                        <h2>Sign Up</h2>
                        <h7 className="text-danger">{errorMsg}</h7>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                className="form-control mb-4 p-4"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter name"
                            />
                            <input
                                type="text"
                                className="form-control mb-4 p-4"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter username"
                            />
                            <input
                                type="email"
                                className="form-control mb-4 p-4"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                            />
                            <input
                                type="password"
                                className="form-control mb-4 p-4"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password"
                            />
                            <br />
                            <button
                                type="submit"
                                className="btn btn-block btn-primary p-2 btnSign"
                                disabled={!name || !email || !password || !username || loading}
                            >
                                {loading ? <CircularProgress style={{ marginLeft: 10 }} size={40} /> : "Submit"}
                            </button>
                        </form>
                        <p className="text-center p-3">
                            Already Registered ? <Link to="/login">Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
