import { useEffect, useState, useContext } from "react";
import axios from "axios";
import LoadingSpinner from '../LoadingSpinner'
import UserNav from "../nav/UserNav";
import { Context } from "../../context";
import { Container, Grid } from '@mui/material';


const UserRoute = ({ children }) => {
  const [ok, setOk] = useState(false);
  const { state } = useContext(Context);
  const { user } = state;

  useEffect(() => {


    const fetchUser = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/user/current`,
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(data);
        setOk(true);
      } catch (err) {
        console.log(err);
        setOk(false);
        // Handle unauthorized access here, e.g., redirect to login page
        // Note: In a real application, you might want to handle this differently
        alert("Session expired or Invalid Token");

        window.localStorage.removeItem("user");

        window.location.href = "/login";
      }
    };

    if (!user) {
      const storedUser = JSON.parse(window.localStorage.getItem("user"));
      if (!storedUser) {
        // User is not logged in, redirect to login page or handle as needed
        window.location.href = "/login";
      } 
    } else {
      fetchUser();
    }
  }, [user]);

  return (
    <Container maxWidth={false} disableGutters>
    {!ok ? (
      <LoadingSpinner />
    ) : (
      <Grid container>
        <Grid item xs={2} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', minHeight:'100vh', backgroundColor: '#f5f5f5' ,flexDirection: 'column',display: 'flex'}}>
          <UserNav/>
        </Grid>
        <Grid item xs={10} sx= {{paddingLeft:'20px'}}>
          {children}
        </Grid>
      </Grid>
    )}
  </Container>
  );
};

export default UserRoute;
