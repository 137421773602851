// src/Footer.js
import React from 'react';
import '../css/footer.css'; // Import the CSS file

function Footer() {
  return (
    <footer>
      <div>
      <img src='https://course-image-data.s3.ap-south-1.amazonaws.com/banner/codecampus-logo.png' alt="Logo" className="logo" />
      </div>
      <ul>
        <li><a href="/terms">Terms of Service</a></li>
        <li><a href="/contact">Contact Us</a></li>
      </ul>
    </footer>
  );
}

export default Footer;
