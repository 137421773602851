// src/TermsAndConditions.js
import React from 'react';
import '../css/TermsAndConditions.css'; // Import the CSS file for styles

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>

      <div className="section">
        <h4>Introduction</h4>
        <p>Welcome to ByteU. These Terms and Conditions govern your use of our services and website. By accessing or using our services, you agree to comply with these terms. If you do not agree, please do not use our services.</p>
      </div>

      <div className="section">
        <h4>Subscription and Billing</h4>
        <p>Our courses are available through a subscription model. You may choose a yearly subscription for our courses. The subscription will automatically renew at the end of each year unless you cancel it before the renewal date.</p>
        <p>If you choose to cancel your subscription, you will retain access to the courses for the remainder of the subscription period. However, access will end once the subscription term is over.</p>
      </div>

      <div className="section">
        <h4>Non-Refundable Policy</h4>
        <p>All payments for subscriptions and courses are non-refundable. Once a payment is made, it cannot be refunded or prorated, even if you decide to cancel your subscription before the end of the term.</p>
      </div>

      <div className="section">
        <h4>User Obligations</h4>
        <p>You agree to use our services in accordance with these Terms and Conditions and not to engage in any unlawful activities or abuse of our services. You are responsible for maintaining the confidentiality of your account information and for all activities under your account.</p>
      </div>

      <div className="section">
        <h4>Modification of Terms</h4>
        <p>We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page and will become effective immediately. Your continued use of our services after such changes constitutes your acceptance of the new Terms and Conditions.</p>
      </div>

      <div className="section">
        <h4>Contact Us</h4>
        <p>If you have any questions or concerns about these Terms and Conditions, please contact us at [Your Contact Information].</p>
      </div>

      <footer>
        <p>&copy; {new Date().getFullYear()} byteU. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
