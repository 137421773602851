import React from 'react';
import { redirectTo } from '../component/Redirect';
import '../css/ThankYouPage.css'; // Import the CSS file for styling
import { useLocation } from 'react-router-dom';

const ThankYouPage = () => {

  const handleContinueShopping = () => {
    redirectTo('/'); // Redirect to home or another page
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get('paymentId'); // Extract paymentId from query parameters
  const orderId = queryParams.get('orderId'); // Extract paymentId from query parameters
  const status = queryParams.get('status'); // Extract paymentId from query parameters


  return (
    <div className="thank-you-container">
      <h1>Thank You!</h1>
      <p>Your Order Id:  <strong> {orderId}</strong></p>

      <p>Payment Reference Id : <strong>{paymentId}</strong></p>
      {status === 'pending' ? (
        <p>Please check your mail for success or failure</p>
      ) : status === 'success' ? (
        <p>Your order is successful!</p>
      ) : status === 'failed' ? (
        <p>Your order has failed. Please try again.</p>
      ) : status === 'cancelled' ? (
        <p>Your order has been cancelled.</p>
      ) : null}

      <button className="continue-shopping-button" onClick={handleContinueShopping}>
        Continue Shopping
      </button>
    </div>
  );
};

export default ThankYouPage;
