import React, { useEffect, useState } from "react";
import axios from "axios";
import { Tabs, Tab, Box, Container, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { Link } from "react-router-dom";
import { IoSearchSharp } from "react-icons/io5";
import { redirectTo } from "../component/Redirect";
import '../css/Home.css';

const style = {
    textDecoration: 'none',
    color: 'inherit',
    height: '250px !important'
};

const Home = () => {
    const [courses, setCourses] = useState([]); // State for storing courses
    const [filteredCourses, setFilteredCourses] = useState([]); // State for storing filtered courses
    const [selectedTab, setSelectedTab] = useState(0); // 0 for Interview, 1 for Gate
    const [searchText, setSearchText] = useState('');

    // Load courses when component mounts or selected tab changes
    useEffect(() => {
        loadCourses();
    }, [selectedTab]);

    // Function to load courses based on the selected category
    const loadCourses = async () => {
        try {
            const category = selectedTab === 0 ? 'interview' : (selectedTab === 1 ? 'gate' : 'tech');
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/list`, {
                params: { category }
            });
            setCourses(response.data.responseData);
            setFilteredCourses(response.data.responseData);
            setSearchText(''); // Clear search input when tab changes
        } catch (error) {
            console.error('Error loading courses:', error);
        }
    };

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Handle search input change
    const handleSearchInput = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchText(searchTerm);
        setFilteredCourses(
            courses.filter(course =>
                course.name.toLowerCase().includes(searchTerm)
            )
        );
    };

    const handleBuy = (courseName) => {
        redirectTo(`/course/${courseName}`);
    };

    const renderCourses = () => (
        <Container>
            <div className="row align-items-center mb-4" style={{ marginTop: '50px', paddingBottom: '100px' }}>
                {filteredCourses.map((course, index) => (
                    <div key={index} className="col-md-3 mb-4 courseBox">
                        <Link to={course.status === 'draft' ? '#' : `/course/${course.name}`} style={style}>
                            <Card  sx={{ height:'340px',width:'280px', display: 'flex', borderRadius:'18px',borderColor:'black', flexDirection: 'column', cursor: course.status === 'draft' ? 'not-allowed' : 'pointer' }}>
                                <CardContent sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', padding:'10px'  }}> 
                                    <CardMedia
                                        component="img"
                                        borderRadius="18px"
                                        height="140"
                                        image={course.status === "draft" ? "https://course-image-data.s3.ap-south-1.amazonaws.com/comin-soon.jpg" : course.url}
                                        alt={course.name}
                                    />
                                    <Box className="box" sx={{ paddingLeft: '5px' }}>
                                        <Typography className="typo" variant="h6" fontFamily='serif' component="div">
                                            {course.name}
                                        </Typography>
                                        
                                    </Box>
                                    <Box className="home-button" sx={{ width: '100%', height: '50px', mt: 'auto', display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleBuy(course.name)}
                                            style={{ background: '#11367d', width: '100%'}}
                                            disabled={course.status === 'draft'}
                                        >
                                            {'Get Started'}
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Link>
                    </div>
                ))}
            </div>
        </Container>
    );

    return (
        <>
            <img src="https://course-image-data.s3.ap-south-1.amazonaws.com/banner/banner-code.png" className="bannerImg" alt="S3 GIF"  />

            <Box sx={{ minHeight: '100vh' }}> {/* Ensure the content area takes up the full height */}
                <Container>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Interview Preparation" />
                        <Tab label="Gate Courses" />
                        <Tab label="Tech Courses" />

                    </Tabs>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: '600px', position: 'relative' }}>
                            <input
                                type="text"
                                className="search-control-home"
                                value={searchText}
                                placeholder="Search Courses"
                                onChange={handleSearchInput}
                                style={{ width: '100%', paddingRight: '30px' }}
                            />
                            {/* <IoSearchSharp className="search-icon" size={20} style={{ position: 'absolute', right: '10px', cursor: 'pointer' }} /> */}
                        </div>
                    </Box>
                    {renderCourses()}
                </Container>
            </Box>
        </>
    );
};

export default Home;
