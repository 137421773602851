import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "../css/ordersummary.css";
import { load } from '@cashfreepayments/cashfree-js';
import { useContext } from 'react';
import { Context } from '../context';
import { redirectTo } from '../component/Redirect';
import '../css/ordersummary.css';
import { auto } from '@popperjs/core';


const BuyingPage = () => {
    const { courseName } = useParams();
    const [course, setCourse] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [finalPrice, setFinalPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); // New state for handling errors
    const { state } = useContext(Context);
    const { user } = state;
    const [phoneNumber, setPhoneNumber] = useState('');

    let cashfree;

    const initializeSDK = async () => {
        try {
            cashfree = await load({
                mode: process.env.REACT_APP_PAYMENT_ENVIRONMENT
            });
        } catch (error) {
            console.error('Failed to initialize Cashfree SDK:', error);
        }
    };
    initializeSDK(); // Ensure SDK is initialized

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${courseName}`);
                setCourse(data.responseData);
                setFinalPrice(data.responseData.price);
            } catch (error) {
                toast.error('Failed to fetch course details');
            }
        };
        fetchCourse();
    }, [courseName, user]);

    const handleCancelCoupon = () => {
        setCouponCode('');
        setError(null);
        setDiscount(0);
        setFinalPrice(course.price);
    };

    const handleApplyCoupon = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/orders/coupon/apply`, { code: couponCode },
                {
                    headers: {
                        Authorization: "Bearer " + user.accessToken,
                        "Content-Type": "application/json",
                    }
                }
            );
            if (response.status === 200) {
                setDiscount(response.data.responseData.amount);
                setFinalPrice(course.price - response.data.responseData.amount);
                toast.success('Coupon applied successfully');
                setError(null); // Clear previous errors

            }

        } catch (error) {
            setError('Invalid coupon code or expired Coupon'); // Set error message
            toast.error('Invalid coupon code');
            setDiscount(0);
            setFinalPrice(course.price);
        }
    };


    const doPayment = async (paymentSessionId, orderId) => {
        let checkoutOptions = {
            paymentSessionId: paymentSessionId,
            redirectTarget: "_modal"
        };

        cashfree.checkout(checkoutOptions).then((result) => {
            if (result.error) {
                // Handle the error case
                console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                console.log(result.error);
                console.log("Payment has been completed successfully");

                window.location.href = `https://byteu.in/user/payment-success/${orderId}`;
            }

            if (result.redirect) {
                // Handle the redirection case
                console.log("Payment will be redirected");
                // You can optionally handle redirection here if needed
                console.log("Payment has been completed successfully");

                window.location.href = `https://byteu.in/user/payment-success/${orderId}`;
            }

            if (result.paymentDetails) {
                // Check if the payment was successful
                console.log("Payment has been completed successfully");

                window.location.href = `https://byteu.in/user/payment-success/${orderId}`;

            }
        }).catch((error) => {
            // Handle any unexpected errors
            console.error("An error occurred during payment processing:", error);
        });
    };



    const handlePurchase = async () => {
        if (!phoneNumber) {
            setError('Phone number is required');
            return;
        }
        try {
            setLoading(true);
            const data = {
                courseId: course.externalId,
                couponCode: couponCode,
                userId: user.externalId,
                phoneNumber: phoneNumber
            };

            try {
                const uri = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CREATE_ORDER}`;
                const res = await axios.post(uri, data, {
                    headers: {
                        Authorization: "Bearer " + user.accessToken,
                        "Content-Type": "application/json",
                    }
                });
                if (res.status == 200) {
                    doPayment(res.data.responseData.data.paymentSessionId, res.data.responseData.data.orderId);
                    toast.success('Purchase successful');
                }
            } catch (error) {
                toast.error('Failed to create order');
            }
        } catch (error) {
            toast.error('Purchase failed');
        } finally {
            setLoading(false);
        }
    };

    if (!course) return <p>Loading...</p>;

    // Total price calculation
    const total = finalPrice > 0 ? finalPrice : course.price;

    return (
        <div className="container-fluid-order-summary">
            <div className="order-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Left Column (70%) */}
                <div className="left-column" style={{ flex: 7, padding: '20px' }}>
                    <h1 style={{ fontFamily: 'auto' }}>Order Summary</h1>
                    <div className="order-item">
                          
                        <div className="total-line">
                        
                            <span><img
                            src={course.url}  // Assuming course.imageUrl contains the image URL
                            alt={course.name}
                            style={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover',
                                borderRadius: '5px',
                                marginRight:'4px'
                            }}
                        />{course.name}</span>
                            <span>₹{course.price.toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="order-item">
                        <div className="total-line">
                            <span>Discount:</span>
                            <span>₹{discount.toFixed(2)}</span>
                        </div>
                    </div>
 
                    <div className="order-total">
                        <div className="total-line">
                            <span>Total:</span>
                            <span>₹{total.toFixed(2)}</span>
                        </div>
                    </div>
                </div>

                {/* Right Column (30%) */}
                <div className="right-column" style={{ flex: 3, paddingRight: '20px',paddingLeft: '20px', paddingTop:'52px',borderLeft: '1px solid #ccc' }}>
                    <h3 style={{ fontFamily: 'emoji' , display:'flex', justifyContent:'center' , borderBottom:'1px solid #ddd' }}>Details</h3>
                    
                <div className="phone-number-section" style={{ marginTop: '15px' , marginBottom:'25px' }}>
                        <label htmlFor="phoneNumber" style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>
                            Phone Number Details
                        </label>
                        <input
                            id="phoneNumber"
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter phone number"
                            style={{width: '90%', paddingRight: '20px',paddingLeft: '20px'
                                , borderRadius: '5px', border: '1px solid #ccc' }}
                        />
                    </div>
                    {/* Coupon Section */}
                    <div className="order-discount" style={{ marginBottom: '20px' }}>
                        <label htmlFor="applyCoupon" style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>
                            Coupon Code
                        </label>
                        <input
                            type="text"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            placeholder="Enter coupon"
                            style={{ width: '70%', padding: '10px',marginTop:'5px' }}
                        />
                        <button className="apply-coupon-button" onClick={handleApplyCoupon} style={{  marginTop:'10px' }}>
                            Apply Coupon
                        </button>
                        <button className="cancel-coupon-button" onClick={handleCancelCoupon} style={{ marginLeft: '5px' }}>
                            ❌
                        </button>

                    </div>

                    {/* Error Message */}
                    <div className="order-discount">
                        {error && <p className="error-message">{error}</p>}
                    </div>

                    {/* Complete Purchase Button */}
                    <button
                        className="complete-purchase-button"
                        onClick={handlePurchase}
                        disabled={loading || !phoneNumber}
                       
                    >
                        {loading ? 'Processing...' : 'Complete Purchase'}
                    </button>
                </div>
            </div>
        </div>



    );
};

export default BuyingPage;
