import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Context } from "../../context";
import LoadingSpinner from "../LoadingSpinner";
import InstructorNav from "../nav/InstructorNav";
import { redirectTo } from "../Redirect";
import { Container, Grid } from '@mui/material';

const InstructorRoute = ({ children }) => {
  const [ok, setOk] = useState(false);
  const { state } = useContext(Context);
  const { user } = state;

  useEffect(() => {
    const fetchInstructor = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/user/current`, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            "Content-Type": "application/json",
          },
        });
        console.log(data);
        setOk(true);
        if (data.responseData.role !== "instructor") {
          redirectTo("/"); // Redirect to homepage if user is not an instructor
        }
      } catch (err) {
        console.log(err);
        setOk(false);
        alert("Session expired or Invalid Token");

        window.localStorage.removeItem("user");
        redirectTo("/login"); // Redirect to login page on error or unauthorized access
      }
    };

    if (!user) {
      const storedUser = JSON.parse(window.localStorage.getItem("user"));
      if (!storedUser) {
        // User is not logged in, redirect to login page or handle as needed
        redirectTo("/login");
      }
    } else {
      fetchInstructor();
    }
  }, [user]);

  return (
    <Container maxWidth={false} disableGutters>
      {!ok ? (
        <LoadingSpinner />
      ) : (
        <Grid container>
          <Grid item xs={2} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', height:'120vh', backgroundColor: '#f5f5f5' }}>
            <InstructorNav />
          </Grid>
          <Grid item xs={10}>
            {children}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default InstructorRoute;
