import React, { useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, ViewMode, ScrollMode } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({ pdfUrl, topicName }) => {
  const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage as 0 (first page)
  const [numPages, setNumPages] = useState(null); // State to hold the total number of pages

  const handleLoadError = (error) => {
    console.error('Error loading PDF:', error);
  };

  return (
    <div style={{ width: '90%', height: '95vh', overflow: 'hidden' }}>
    {/* Wrapper container */}
    <div style={{ 
      border: '1px solid #ccc', 
     
      height: '100%', 
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {/* Transform container */}
      <div style={{ 
        transform: 'scale(0.70)', // Adjust scale factor as needed
        transformOrigin: '0 0', // Scale from the top-left corner
        width: '133.33%', // Inverse of scale factor (100% / 0.75)
        height: '133.33%', // Inverse of scale factor (100% / 0.75)
      }}>
         <Worker workerUrl='/pdf.worker.min.js'>
          <Viewer
            fileUrl={pdfUrl}
            page={currentPage}
            scale={1} // Set scale to 1 for actual size within the transformed container
            onPageLoad={({ numPages }) => setNumPages(numPages)} // Update numPages state on PDF load
            onPageChange={({ pageIndex }) => setCurrentPage(pageIndex)} // Update currentPage state on page change
            onError={handleLoadError}
            style={{ width: '100%', height: '100%' }} // Ensure the viewer takes up the full transformed container
          />
        </Worker>
      </div>
    </div>
  </div>


    // <div style={{ width: '100%', height: '95vh', border: '1px solid #ccc', overflow: 'hidden' }}>
    //     <Worker workerUrl='/pdf.worker.min.js'>
    //         <Viewer
    //             fileUrl={pdfUrl}
    //             page={currentPage}
    //             zoom={SpecialZoomLevel.PageFit} 
    //             onPageLoad={({ numPages }) => setNumPages(numPages)} // Update numPages state on PDF load
    //             onPageChange={({ pageIndex }) => setCurrentPage(pageIndex)} // Update currentPage state on page change
    //             onError={handleLoadError}
    //             scrollMode={ScrollMode.Wrapped}
    //             viewMode={ViewMode.SinglePage}
    //         />
    //     </Worker>
    // </div>

  );
};

export default PdfViewer;
