import { Badge, Box, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import '../../css/SingleCourseJumbotron.css';
import DesignTable from '../DesignTable';
import { useState } from 'react';
import LessAbout from '../LessAbout';
import ExpandableTopic from '../../pages/course/course-list';
import SingleCourseLessions from './SingleCourseLessons';
import CommentBox from '../../pages/user/comment/CommentBox';

const SingleCourseJumbotron = ({ course, topics, setPreview, loading,user, handleFreeEnrollment, handlePaidEnrollment, enrolled }) => {
    const { name, courseAdditionalInfo, price, paid, updatedAt } = course;
    const category = course.category; // Example category
    const [activeTab, setActiveTab] = useState("about"); // Default tab

    return (

        <div className="squares">

            <div className="container-fluid content">
                <div className="row">
                    <div className="col-md-12">
                    <div className="col-md-8 bannerLeft" >
                        <h2 className="course-name">{name}</h2>
                        <p style={{ fontSize: '1.0rem', fontWeight: 100, color: 'rgb(75 65 75)', textAlign: 'justify' }}>
                            {courseAdditionalInfo &&
                                courseAdditionalInfo.description &&
                                courseAdditionalInfo.description.substring(0, 200)}

                        </p>
                        <Box display="flex">
                            <p style={{ fontSize: '14px', textAlign: 'justify', color: 'black' }}>Last Updated: {format(new Date(updatedAt), 'dd/MM/yyyy')}</p>

                        </Box>
                        <div>
                            <div className="buttonCourse">
                                <Button
                                    variant="contained"
                                    sx={{
                                        bgcolor: enrolled ? '#11367d' : '#11367d',  // Background color based on 'enrolled' state
                                        '&:hover': {
                                            bgcolor: enrolled ? '#11367d' : '#0d2b5f',
                                        },
                                    }}
                                    onClick={paid ? handlePaidEnrollment : handleFreeEnrollment}
                                >
                                    {enrolled ? (
                                        'Go to Course'
                                    ) : (
                                        <>
                                            Enroll {paid && ` (₹${price}/Yr)`} {/* Price appears if paid */}
                                        </>
                                    )}
                                </Button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            </div>
                <DesignTable level={course.level} totalLessons={course.totalLessons}/>
                {/* Tabs Section */}
            <div className="tabs-container">
                <div className="tabs">
                    <button className={activeTab === "about" ? "active" : ""} onClick={() => setActiveTab("about")}>
                        About
                    </button>
                    <button className={activeTab === "lessons" ? "active" : ""} onClick={() => setActiveTab("lessons")}>
                        Lessons
                    </button>
                    <button className={activeTab === "reviews" ? "active" : ""} onClick={() => setActiveTab("reviews")}>
                        Reviews
                    </button>
                </div>                
            </div>
            <div className="tab-content">
                    {activeTab === "about" && <LessAbout course={course} />}
                    {activeTab === "lessons" && <div className="less-about-container"><SingleCourseLessions topics={topics}  course={course} enrolled={enrolled} user={user}/></div>
                }
                    {activeTab === "reviews" && <div className="less-about-container"><CommentBox courseId={course.externalId} /></div>}
                </div>
        </div>
    )
};


export default SingleCourseJumbotron;



{/* <div className="col-md-4 d-flex flex-column bannerRight">
<div className="banner-content">
    <div className="video-container">
        <div className="thumbnail" >
          
             <img src={course.url}
                
            />
        </div>
    </div>
    <div className="col-md-9 enroll-container">
        {loading ? (
            <div className="d-flex justify-content-center">
                <h1>Loading...</h1>
            </div>
        ) : (

            
//         )}
//     </div>
// </div>

// </div> */}