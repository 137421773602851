import React, { useState, useContext } from 'react';
import axios from 'axios';
import PdfViewer from '../../../component/PdfViewer';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  IconButton
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import PreviewModalPdf from '../../../component/modal/PreviewModalPdf';
import PreviewModalHtml from '../../../component/modal/HtmlModal';
import CodeEditorModal from '../../../component/modal/CodeEditorModal';
import { Context } from '../../../context';
import { redirect } from 'react-router-dom';
import { redirectTo } from '../../../component/Redirect';
import DOMPurify from 'dompurify';
import SuccessPopup from '../../../component/SuccessPopup';

const ExpandableTopics = ({ topics, preview, enrolled, user, canAddCode }) => {
  const [expanded, setExpanded] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showHTMLContent, setShowHTMLContent] = useState(false);
  const [htmlContent, setHtmlContent] = useState(null);
  const [showCodeEditorModal, setShowCodeEditorModal] = useState(false); // State to handle code editor modal
  const [selectedLesson, setSelectedLesson] = useState(null); // Track the selected lesson for code editor
  const [isVisible,setVisible]= useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLessonClick = (lesson) => {
    if (preview || !lesson.paid) fetchSignedUrl(lesson);
    if (!preview && lesson.paid && enrolled) fetchSignedUrl(lesson);
  };

  const fetchSignedUrl = async (lesson) => {
    if (!lesson || !lesson.pdfUrl) {
      if (lesson.description && lesson.description.length > 5) {
        await getHtmlContent(lesson);
      }
      return;
    }
    if (!user) {
      redirectTo("/login");
    }
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/documents/signed-url`, {
        params: {
          documentKey: lesson.pdfUrl
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        },
      });
      setSignedUrl(data.responseData);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  const onClose =()=>{
    setVisible(false);
  }
  async function getHtmlContent(lesson) {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/courseId/topics/${lesson.externalId}/content`, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      let html =DOMPurify.sanitize(data.responseData.content, {
        ADD_TAGS: ['iframe'], // Allow iframe tag
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'src'], // Allow iframe attributes
        FORBID_ATTR: ['srcdoc'], // Forbid potentially dangerous attributes
        ALLOW_URI_REGEXP: /^https:\/\/www\.youtube\.com\/embed\// // Allow only YouTube iframe src links
      });
      setHtmlContent(html);
    } catch (error) {
      console.error("Error fetching signed URL:", error);
    } finally {

    }
    setSignedUrl(null);
    setShowHTMLContent(true);
  }

  const handleCodeEditorClick = (lesson) => {
    setSelectedLesson(lesson); // Set the selected lesson
    setShowCodeEditorModal(true); // Show the modal
  };

  // Function to handle saving the code description and test cases
  const handleSaveCodeData = async (lessonId, codeData) => {
    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/testcases/entity`,
        codeData,{
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        }
      );
      console.log("Test case Created", data);
      alert("added");
    } catch (err) {
      console.error("Error creating course", err);
    }

  };

  return (
    <div>
      {topics && topics.length > 0 && topics.map((topic) => (
        <Accordion className='accord-toggle'
          key={topic.topicId}
          expanded={expanded === topic.topicId}
          onChange={handleChange(topic.topicId)}
          style={{  marginBottom: '10px' }}
        >
          <AccordionSummary
            aria-controls={`panel${topic.topicId}-content`}
            id={`panel${topic.topicId}-header`}
            onClick={() => handleChange(topic.topicId)}
            style={{
              backgroundColor: '#11367d',
              padding: '2px 8px',
              minHeight: '30px',
              height: '50px',
              alignItems: 'center',
              color: '#fff',
              cursor: 'pointer'
            }}
          >
            <Typography style={{ flexGrow: 1, paddingTop: '8px', paddingLeft: '6px' }}>{topic.topicName}</Typography>
            <Button
              variant="link"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleChange(topic.topicId);
              }}
            >
              {expanded === topic.topicId ? (
                <i className="bi bi-chevron-up" style={{ cursor: 'pointer' }}></i>
              ) : (
                <i className="bi bi-chevron-down" style={{ cursor: 'pointer' }}></i>
              )}
            </Button>
          </AccordionSummary>
          <AccordionDetails style={{
            backgroundColor: 'white',
            padding: '2px 8px',
            minHeight: '50px'
          }}>
            <List component="nav" aria-label="lessons">
              {topic.lessons && topic.lessons.length > 0 && topic.lessons.map((lesson, index) => (
                <div key={lesson.externalId}>
                  <ListItem
                    button
                    onClick={() => handleLessonClick(lesson)}
                  >
                    <ListItemText primary={lesson.name} />
                    {lesson.codeEditor && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCodeEditorClick(lesson);
                        }}
                      >
                        Add Code
                      </Button>
                    )}
                    <IconButton disabled={lesson.paid}>
                      {lesson.paid ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  </ListItem>
                  {index !== topic.lessons.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
      {signedUrl && <PreviewModalPdf showModal={showModal} setShowModal={setShowModal} src={signedUrl}></PreviewModalPdf>}
      {showCodeEditorModal && (
        <CodeEditorModal
          showModal={showCodeEditorModal}
          setShowModal={setShowCodeEditorModal}
          codeQuestionId={selectedLesson.externalId}
          questionType={'topic'}
          onSave={handleSaveCodeData}
        />
      )}
    </div>
  );
};

export default ExpandableTopics;
