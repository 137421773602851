import { useState, useEffect,useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Select, MenuItem, Avatar, Modal, Box, Typography } from "@mui/material";
import { Tooltip, Button, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Markdown from "react-markdown";
import AddLessonForm from "../../../../component/forms/AddLessonForm";
import { toast } from "react-toastify";
import ExpandableTopics from "../topic-list";
import { redirectTo } from "../../../../component/Redirect";
import { Context } from "../../../../context";
import { PlayCircleOutline } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const   CourseView = () => {
  const [course, setCourse] = useState(null);
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState({
    title: "",
    content: "",
    video: "",
    topic: "",
    pdf: "",
    paid: true,
    testAvailable:true,
    codeEditor:true
  });
  const [uploading, setUploading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [topics, setTopics] = useState([]);
  const [uploadVideoButtonText, setUploadVideoButtonText] = useState("Upload Video");
  const [uploadPDFButtonText, setUploadPDFButtonText] = useState("Upload PDF");
  const [progress, setProgress] = useState(0);
  const {state} = useContext(Context)
  const {user} = state;
  const { slug } = useParams();

  const handleClose = () => {
    setVisible(false);
    setUploadVideoButtonText("Upload Video");
    setUploadPDFButtonText("Upload PDF");
    setProgress(0);
  };

  const handleVideo = async (e) => {
    setUploading(true);

    try {
      const file = e.target.files[0];
      setUploadVideoButtonText(file.name);
      const videoData = new FormData();
      videoData.append('file', file);

      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/courses/upload-video`, videoData,{
        headers: {
          Authorization: "Bearer " + user.accessToken
        }
      }, {
        onUploadProgress: (e) => {
          setProgress(Math.round((100 * e.loaded) / e.total));
        }
      });

      setValues({ ...values, video: data });
      setUploading(false);
      toast.success("Video uploaded successfully");
    } catch (err) {
      console.error('Error uploading video:', err);
      setUploading(false);
      toast.error("Video upload failed");
    }
  };

  const handlePDF = async (e) => {
    setUploading(true);

    try {
      const file = e.target.files[0];
      setUploadPDFButtonText(file.name);
      const pdfData = new FormData();
      pdfData.append('file', file);

      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/courses/upload-pdf`, pdfData,{
        headers: {
          Authorization: "Bearer " + user.accessToken
        }
      }, {
        onUploadProgress: (e) => {
          setProgress(Math.round((100 * e.loaded) / e.total));
        }
      });

      setValues({ ...values, pdf: data });
      setUploading(false);
      toast.success("Document uploaded successfully");
    } catch (err) {
      console.error('Error uploading document:', err);
      setUploading(false);
      toast.error("Document upload failed");
    }
  };

  useEffect(() => {
    if (slug) {
      loadCourse();
    }
  }, [slug]);

  const loadCourse = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${slug}`,{
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      setCourse(data.responseData);
      loadLessons(data.responseData.externalId);
    } catch (err) {
      console.error('Error loading course:', err);
    }
  };

  const loadLessons = async (externalId) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${externalId}/topics/list`,{
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      setTopics(convertTopics(data.responseData));
    } catch (err) {
      console.error('Error loading lessons:', err);
    }
  };

  function convertTopics(response) {
    // Check if response and responseData exist
    if (!response || !Array.isArray(response)) {
      return []; // Return empty array if response format is incorrect
    }

    // Convert response to array of topics with lessons
    const topicsArray = response.reduce((acc, topic) => {
      // Check if topic_id already exists in accumulator
      const existingTopic = acc.find(t => t.topicSlug === topic.topicSlug);

      if (existingTopic) {
        // If topic_id already exists, push lesson to existing topic's lessons array
        existingTopic.lessons.push({
          externalId: topic.externalId,
          rank: topic.rank,
          description: topic.description,
          videoUrl: topic.videoUrl,
          pdfUrl: topic.pdfUrl,
          name: topic.name,
          paid: topic.paid,
          codeEditor:topic.codeEditor

        });
      } else {
        // If topic_id doesn't exist, create a new topic object
        acc.push({
          topicName: topic.topicName,
          topicSlug: topic.topicSlug,
          topicId: topic.topicId,
          lessons: [
            {
              externalId: topic.externalId,
              rank: topic.rank,
              description: topic.description,
              videoUrl: topic.videoUrl,
              pdfUrl: topic.pdfUrl,
              name: topic.name,
              paid: topic.paid,
              codeEditor:topic.codeEditor
            }
          ]
        });
      }

      return acc;
    }, []);

    return topicsArray;
  }

  const handleAddLesson = async (contentBody) => {

    try {
      const courseId = course.externalId;
      
      const req = {
        name: values.title,
        content: contentBody,
        topic: values.topic,
        video: values.video,
        pdf: values.pdf,
        paid: values.paid,
        testAvailable:values.testAvailable,
        codeEditor:values.codeEditor
      };

      console.log(req)

      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/topics`, req,{
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
        }
      });
      toast.success("Lesson added successfully");
      handleClose();
      window.location.reload(); // You might want to use state management to update topics instead of reloading the page
    } catch (err) {
      console.error('Error adding lesson:', err);
      toast.error("Lesson upload failed");
    }
  };

  const onEdit = () => {
    redirectTo(`/instructor/course/edit/${slug}`)
  };

  const onPublish = () => {
    // Handle publish action
  };

  return (
      <div className="container-fluid pt-3">
        {course && (
          <div className="container-fluid pt-1">
            <div className="d-flex align-items-center pt-2">
              <Avatar
                src={course.url}
                sx={{
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                }}
              />

              <div className="flex-grow-1 pl-2">
                <h5 className="mt-2 text-primary"> {course.name}</h5>
                <p style={{marginLeft:'10px', marginTop: '-15px', fontSize: '-10px' }}>
                   Rs: {course.price}
                </p>
              </div>

              <div className="d-flex">
                <Tooltip title="Edit">
                  <IconButton onClick={onEdit}>
                    <div className="edit-button">edit</div>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Publish">
                  <IconButton onClick={onPublish}>
                    <div className="publish-button">publish</div>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col">
                <Markdown>{course.courseAdditionalInfo && course.courseAdditionalInfo.description}</Markdown>
              </div>
            </div>

            <div className="row button-container">
              <Button
                onClick={() => setVisible(true)}
                variant="contained"
                color="primary"
                shape="round"
                size="small"
                className="custom-button-lesson"
              >
                Add Lesson
              </Button>
            </div>
            <br />

            <Modal
              open={visible}
              onClose={null}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              
            >
              <Box sx={style}>
                <Box mb={2} borderBottom={1}>
                  <Typography id="modal-title" variant="h6" component="h2">
                    + Add Lesson
                  </Typography>
                </Box>
                <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto', mt: 2 }}>
                <Typography id="modal-description" sx={{ mt: 2 }}>
                  <AddLessonForm
                    values={values}
                    setValues={setValues}
                    handleAddLesson={handleAddLesson}
                    handleClose={handleClose}
                    uploadVideoButtonText={uploadVideoButtonText}
                    handleVideo={handleVideo}
                    progress={progress}
                    handlePDF={handlePDF}
                    uploadPDFButtonText={uploadPDFButtonText}
                    user={user}
                  />
                </Typography>
                </Box>
              </Box>
            </Modal>

            <ExpandableTopics topics={topics} preview={true} user={user} canAddCode = {true} />

          </div>
        )}
      </div>
  );
};

export default CourseView;
