import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const TestCard = ({ test, handleViewTest, isDisabled, isDone }) => {
  return (
    <Card
      sx={{
        width: 275,
        marginBottom: 2,
        border: '1px solid #ccc', // Add a border
        borderRadius: 2, // Optional: Add border radius for rounded corners
        boxShadow: 2, // Optional: Add a subtle shadow for better appearance
      }}
    >
      {/* Background and Test Name Section */}
      <Box
        sx={{
          height: '100px', // Fixed height for the background section
          backgroundColor: '#006e55', // Light blue background
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '2px 2px 0 0', // Rounded corners for the top
          position: 'relative',
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '0 12px', // Add some padding to the sides
          }}
        >
          {test.testName}
        </Typography>
      </Box>

      {/* Content Section */}
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 1,
      }}>
        <Typography variant="body2" color="text.secondary" sx={{
          mb: 1,
          textAlign: 'center',
        }}>
          <strong>Time Limit:</strong> {test.testDuration} minutes
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          mb: 1,
          textAlign: 'center',
        }}>
          <strong>Number of Questions:</strong> {test.numQuestions}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          mb: 1,
          textAlign: 'center',
        }}>
          <strong>Number of Attempts:</strong> {test.attempts}/3
        </Typography>
        <Box sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Button
            variant="contained"
            disabled={isDisabled}
            color="primary"
            onClick={() => handleViewTest(test.externalId)} // Pass test ID to the handler
          >
            {isDisabled ? 'Max Retries Reached' : isDone ? 'View Result' : 'Start'}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TestCard;
