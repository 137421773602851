import React from 'react';
import { Modal, Box } from '@mui/material';

const ImageModal = ({ open, handleClose, imgSrc }) => {
    return (
            <Modal open={open} onClose={handleClose} style={{ display: 'flex', justifySelf: 'center',  maxHeight:'75%', top:'12%'}}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10%',
                        background: 'white'
                    }}
                >
                    <img src={imgSrc} alt="Enlarged" style={{ maxWidth: '90vw', maxHeight: '90vh', padding: '20px' }} />
                </Box>
            </Modal>
    );
};

export default ImageModal;
