import React, { useState } from 'react';
import { Select,MenuItem,Modal, Button, TextField, Typography, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { auto } from '@popperjs/core';

const CodeEditorModal = ({ showModal, setShowModal, codeQuestionId, onSave, questionType }) => {
    const [codeDescription, setCodeDescription] = useState('');
    const [testCases, setTestCases] = useState([{ input: '', expectedOutput: '' }]);
    const [systemCode, setSystemCode] = useState('');
    const [langSupported, setLangSupported] = useState('');

    // Function to handle adding a new test case
    const handleAddTestCase = () => {
        setTestCases([...testCases, { input: '', expectedOutput: '' }]);
    };

    // Function to handle removing a test case
    const handleRemoveTestCase = (index) => {
        setTestCases(testCases.filter((_, i) => i !== index));
    };

    // Function to handle saving the code description and test cases
    const handleSave = () => {
        const codeData = {
            entityId:codeQuestionId,
            codeDescription,
            systemCode,
            testcases:testCases,
            questionType,
            langSupported
        };
        onSave(codeQuestionId, codeData);
        setShowModal(false);
    };

    return (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    maxHeight: '90vh', // Set max-height to make it responsive
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    overflow: 'auto'
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Add Code Description and Test Cases
                </Typography>
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <Select
                                style={{ width: "100%" }}
                                size="large"
                                value={langSupported}
                                onChange={(e) => setLangSupported(e.target.value)}>
                                <MenuItem value='java'>Java</MenuItem>
                                <MenuItem value='python'>Python</MenuItem>
                                <MenuItem value='go'>Go</MenuItem>
                                <MenuItem value='c'>c</MenuItem>
                                <MenuItem value='cpp'>c++</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                <textarea
                    style={{ width: '100%',
                        marginTop: '34px',
                        padding: '37px',
                        height: '289px' }} // fullWidth equivalent with padding and margin
                    placeholder="Candidate Code Description" // label equivalent as a placeholder
                    rows={4} // number of rows for multiline
                    value={codeDescription} // value binding
                    onChange={(e) => setCodeDescription(e.target.value)} // handle value change
                />
                <textarea
                    style={{ width: '100%',
                        marginTop: '34px',
                        padding: '37px',
                        height: '289px' }} // fullWidth equivalent with padding and margin
                    placeholder="System Code Description" // label equivalent as a placeholder
                    rows={4} // number of rows for multiline
                    value={systemCode} // value binding
                    onChange={(e) => setSystemCode(e.target.value)} // handle value change
                />
                <Typography variant="h6">Test Cases</Typography>
                {testCases.map((testCase, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={2}>
                        <TextField
                            label="Input"
                            value={testCase.input}
                            onChange={(e) =>
                                setTestCases(
                                    testCases.map((tc, i) =>
                                        i === index ? { ...tc, input: e.target.value } : tc
                                    )
                                )
                            }
                            margin="normal"
                            style={{ marginRight: 10 }}
                        />
                        <TextField
                            label="Expected Output"
                            value={testCase.expectedOutput}
                            onChange={(e) =>
                                setTestCases(
                                    testCases.map((tc, i) =>
                                        i === index ? { ...tc, expectedOutput: e.target.value } : tc
                                    )
                                )
                            }
                            margin="normal"
                            style={{ marginRight: 10 }}
                        />
                        <IconButton
                            onClick={() => handleRemoveTestCase(index)}
                            color="secondary"
                            disabled={testCases.length === 1} // At least one test case should always be present
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Box>
                ))}
                <Button onClick={handleAddTestCase} color="primary" startIcon={<AddIcon />}>
                    Add Test Case
                </Button>
                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CodeEditorModal;
