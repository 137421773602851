// About.js (or About.jsx)
import React from 'react';

const About = () => {
  return (
    <div>
      <div className='container'>
      <div class="clearfix" style={{marginTop : '100px'}}>

      <div className='col-md-6 aboutLeft' >
        <div className="pull-left">
           <h5>About us</h5>
           <p>We are dedicated to building technology solutions that empower technical professionals in an affordable way. Our team brings extensive experience from top startups, service,
             and product companies. We are committed to leveraging our expertise to help the community thrive and grow in the ever-evolving 
             tech landscape.</p>
            <p>
            At our core, we believe in the power of technology to transform lives and businesses. Our mission is to provide innovative, reliable, and cost-effective solutions that meet the diverse needs of our clients.
             Whether you are a small startup or a large enterprise, we have the tools and expertise to help you succeed.
            </p>
            <p>
            Our journey began with a simple idea: to make high-quality tech solutions accessible to everyone. Over the years,
             we have built a strong reputation for delivering exceptional products and services that drive results.
             Our team of experts works tirelessly to stay ahead of industry trends and ensure that our clients always have the best possible solutions at their fingertips.
            </p>
            <p>
            Collaboration and community are at the heart of everything we do. We are proud to have partnered with some of the leading names in the tech industry, and we are always looking for new ways to give back. Through our various initiatives,
             we strive to support and nurture the next generation of tech talent, fostering a culture of innovation and excellence
            </p> <p>
            Looking ahead, we are excited about the future and the endless possibilities that technology holds. As we continue to grow and evolve, our focus remains on delivering unparalleled value to our clients and making a positive impact on the world.
             Thank you for being a part of our journey, and we look forward to building a brighter, more connected future together.
            </p> 
        </div>
        </div>
        <div className='col-md-6 aboutRight'>
        <div className="pull-right">
           <img src = "https://course-image-data.s3.ap-south-1.amazonaws.com/aboutUs.png" className='imgRight' />
            </div>
        </div>
    </div>
      </div>
    </div>
  );
};

export default About;
