import React, { useState, useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { java } from '@codemirror/lang-java';
import { python } from '@codemirror/lang-python';
import { Button, Select, MenuItem, CircularProgress, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { oneDark } from '@uiw/react-codemirror';
import { red } from '@mui/material/colors';
import '../css/code.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const CodeEditor = ({ questionId, user, initialCode, systemCode, langSupport, toggleContentCollapse, isCollapsed }) => {
    const [language, setLanguage] = useState(langSupport);
    const [compilerError, setCompilerError] = useState(null);
    const [code, setCode] = useState(initialCode || ''); // Set default code from props or empty if not provided
    const [testCases, setTestCases] = useState([]);
    const [batchStatus, setBatchStatus] = useState(null);
    const [batchLoading, setBatchLoading] = useState(false);
    const [openAIButtonLoading, setOpenAIButtonLoading] = useState(false);
    const [complexityData, setComplexityData] = useState(null);
    const [error, setError] = useState(null);
    const [testCaseExpanded, setTestCaseExpanded] = useState(null);
    // Judge0 API URL and key
    const JUDGE0_API_URL = process.env.REACT_APP_BASE_URL + '/v1/compiler';

    useEffect(() => {
        if (questionId && user) fetchTestCases();
    }, [questionId, user]);

    useEffect(() => {
        // Check all test cases when testCases state updates
        const allChecked = testCases.every((testCase) => testCase.status !== 'pending');
        const passedCount = testCases.filter((testCase) => testCase.status === 'passed').length;

        if (allChecked && testCases.length > 0) {
            saveResult(questionId, user, passedCount === testCases.length);
        }
    }, [testCases]); // Run this effect whenever testCases state changes


    useEffect(() => {
        if (initialCode !== code) {
            setCode(initialCode);
        }
    }, [initialCode]);

    const fetchTestCases = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/testcases`, {
                params: {
                    questionId: questionId
                },
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            const fetchedTestCases = response.data.map((testCase, index) => ({
                ...testCase,
                id: index + 1, // Assign a sequential ID starting from 1
                input: testCase.input.replace(/\\n/g, '\n').replace(/\\t/g, '\t'), // Replace escaped characters
                expectedOutput: testCase.expectedOutput.replace(/\\n/g, '\n').replace(/\\t/g, '\t'), // Replace escaped characters
                status: 'pending',
                loading: false,
                result: null,
                expanded: false
            }));

            setTestCases(fetchedTestCases); // Update state with transformed test cases
        } catch (error) {
            console.error('Error fetching test cases:', error);
        }
    }

    const getLanguageId = () => {
        switch (language) {
            case 'java':
                return 62; // Java language ID
            case 'python':
                return 71; // Python language ID
            default:
                return 62;
        }
    };

    const getLanguageExtension = () => {
        switch (language) {
            case 'java':
                return java();
            case 'python':
                return python();
            default:
                return java(); // Default to Java if no language is selected
        }
    };

    const runFirstTestCase = async () => {
        setBatchLoading(true)
        const firstTestCase = testCases[0];
        setBatchStatus(null); // Reset batch status
        setCompilerError(null); // Reset compiler error
        setTestCaseExpanded(null)
        setTestCases((prev) =>
            prev.map((tc) =>
                tc.id === firstTestCase.id
                    ? { ...tc, loading: true, status: 'pending', result: null }
                    : { ...tc, loading: true, status: 'pending', result: null, expanded: false }
            )
        );

        try {
            const response = await axios.post(
                `${JUDGE0_API_URL}/run-code?base64_encoded=false&wait=true`,
                {
                    source_code: code + systemCode,
                    language_id: getLanguageId(),
                    stdin: firstTestCase.input,
                    expected_output: firstTestCase.expectedOutput
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                }
            );

            const { stdout, status, compile_output, stderr } = response.data;

            // Initialize status of the test case (pass/fail)
            let passed = false;
            let errorMessage = null;

            if (status.id === 6) {
                errorMessage = `Compile Error: ${compile_output}`;
            } else if (status.id === 11) {
                errorMessage = `Runtime Error: ${stderr || 'Unknown error'}`;
            } else {
                // Parse the JSON output from stdout
                const results = stdout.trim();
                const parsedResults = results
                    .split('}{')  // Handle multiple JSON objects within the same stdout
                    .map((res, index, array) => {
                        // Add braces to each JSON object
                        if (array.length > 1) {
                            if (index === 0) return res + '}';
                            if (index === array.length - 1) return '{' + res;
                            return '{' + res + '}';
                        }
                        return res;
                    })
                    .map((res) => JSON.parse(res)); // Parse each JSON string

                // Create a map to match test case results with IDs
                const testCaseResultsMap = new Map();

                // Store parsed results in the map with `testcase` as the key
                parsedResults.forEach((parsedResult) => {
                    testCaseResultsMap.set(parsedResult.testcase, parsedResult);
                });
                // Now, update the state of the test cases based on the parsed results
                setTestCases((prev) =>
                    prev.map((tc) => {
                        const result = testCaseResultsMap.get(tc.id);
                        if (result) {
                            // Update the test case with the result from stdout
                            const passed = result.status === 'pass';
                            return {
                                ...tc,
                                loading: false,
                                status: passed ? 'passed' : 'failed',
                                result: result.output, // Store the output from stdout
                            };
                        }
                        return { ...tc, loading: false, status: 'pending', result: null }; // Keep other test cases reset
                    })
                );


                // Update batch status for the first test case
                const passedCount = parsedResults.filter((tc) => tc.status === 'pass').length;
                const resultResponse = `${passedCount} / ${testCases.length} Passed${errorMessage ? ` - ${errorMessage}` : ''}`;

                setBatchStatus(resultResponse);

            }

            if (errorMessage) {
                setBatchStatus(errorMessage); // Show error below buttons
            }
        } catch (error) {
            setTestCases((prev) =>
                prev.map((tc) =>
                    tc.id === firstTestCase.id
                        ? { ...tc, loading: false, status: 'error', result: error.response ? error.response.data : error.message }
                        : { ...tc, loading: false, status: 'pending', result: null }
                )
            );
            setBatchStatus('Failed to run the test case.');
        } finally {
            setBatchLoading(false)
        }
    };

    const submitBatch = async () => {
        setBatchLoading(true);
        setBatchStatus(null); // Reset batch status
        setCompilerError(null); // Reset compiler error

        // Set all test cases to loading
        setTestCases((prev) =>
            prev.map((tc) => ({
                ...tc,
                loading: true,
                status: 'pending',
                expanded: false, // Collapse test cases while batch is running
            }))
        );

        const submissions = testCases.map((testCase) => ({
            source_code: code,
            language_id: getLanguageId(),
            stdin: testCase.input,
            expected_output: testCase.expectedOutput,
        }));

        try {
            const response = await axios.post(
                `${JUDGE0_API_URL}/submit-batch?base64_encoded=false&wait=true`,
                { submissions: submissions, questionId: questionId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                }
            );

            const tokens = response.data.map((result) => result.token);

            // Check each test case status individually with a timeout
            const checkStatusPromises = tokens.map((token, index) =>
                checkSubmissionStatus(token, index)
            );

            // Wait for all promises with a maximum of 20 seconds timeout
            await Promise.allSettled(checkStatusPromises); // Use allSettled to ensure all submissions are checked

        } catch (error) {
            console.error('Batch submission failed:', error);
            setTestCases((prev) =>
                prev.map((tc) =>
                    tc.loading ? { ...tc, status: 'failed', loading: false } : tc
                )
            );
            if (error.status === 400) {
                setError('Failed to submit the batch, You have reached the maximum number of submissions for this question today');
            } else {
                setError("Failed to submit the batch")
            }
        } finally {
            setBatchLoading(false);
        }
    };

    async function saveResult(questionId, user, passedAll) {
        try {
            const data = {
                userId: user.externalId,
                code: code,
                passed: passedAll
            };

            const response = await axios.post(process.env.REACT_APP_BASE_URL + `/v1/compiler/questions/${questionId}/submit`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.accessToken
                }
            });

            if (response.status === 200) {
                console.log('Result saved successfully:', response.data);
            } else {
                console.log('Failed to save result:', response.status);
            }
        } catch (error) {
            alert("Error while saving, please re-submit")
            console.error('Error while saving result:', error);
        }
    }

    const updateTestCaseStatus = (prev, index, passed, result) => {
        const updatedTestCases = [...prev];
        updatedTestCases[index] = {
            ...updatedTestCases[index],
            loading: false,
            status: passed ? 'passed' : 'failed',
            result: result,
        };
        return updatedTestCases;
    };

    const checkSubmissionStatus = async (token, index) => {
        try {
            let result;
            do {
                await new Promise((resolve) => setTimeout(resolve, 2000)); // Poll every 2 seconds
                result = await axios.get(`${JUDGE0_API_URL}/status/${token}?base64_encoded=false`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                });
            } while (result.data.status.id <= 2); // Wait until the status is not 'In Queue' or 'Processing'

            const { status, stdout, compile_output, stderr } = result.data;
            const passed = status.id === 3 && stdout.trim() === testCases[index].expectedOutput.trim();
            let errorMessage = null;

            if (status.id === 6) {
                errorMessage = `Compile Error: ${compile_output}`;
            } else if (status.id === 11) {
                errorMessage = `Runtime Error: ${stderr || 'Unknown error'}`;
            }

            setTestCases((prev) => {
                const updatedTestCases = updateTestCaseStatus(prev, index, passed, result.data);
                setBatchStatus((prevStatus) => {
                    const passedCount = updatedTestCases.filter((tc) => tc.status === 'passed').length;
                    return `${passedCount} / ${updatedTestCases.length} Passed${errorMessage ? ` - ${errorMessage}` : ''}`;
                });
                return updatedTestCases;
            });
        } catch (error) {
            setTestCases((prev) =>
                prev.map((tc, idx) =>
                    idx === index
                        ? { ...tc, loading: false, status: 'error', result: error.response ? error.response.data : error.message }
                        : tc
                )
            );
        }
    };

    const toggleExpansion = (id) => {
        setTestCases((prev) =>
            prev.map((tc) => (tc.id === id ? { ...tc, expanded: !tc.expanded } : tc))
        );
    };

    const fetchComplexity = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v1/compiler/complexity`,
                { code: code, questionId: questionId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                }
            );

            if (response.status === 200) {
                const jobId = response.data.responseData;
                // Start polling to check the job status
                if (jobId) {
                    await pollForResult(jobId);
                }
            }

        } catch (error) {
            console.error('Complexity submission failed:', error);
            let errorMessage = error.response ? error.response.data : error;
            setError(errorMessage)
        } finally {
            setOpenAIButtonLoading(false);
        }
    };

    // Polling function to check job status every second for 5 seconds
    const pollForResult = async (jobId) => {
        const maxRetries = 5;  // Set the maximum retries
        let retries = 0;

        while (retries < maxRetries) {
            try {
                // Wait for 1 second between polls
                await new Promise(resolve => setTimeout(resolve, 2000));

                // Check the job status
                const resultResponse = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/v1/compiler/complexity/${jobId}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + user.accessToken
                        }
                    }
                );

                if (resultResponse.status === 200) {
                    const result = resultResponse.data;

                    if (result) {
                        // Job is complete, handle the result
                        setComplexityData(result);
                        return;
                    }
                }

            } catch (error) {
                console.error('Polling failed:', error);
            }

            retries++;
        }

        // If 5 seconds have passed and no result, show a "please try later" message
        alert("The response is taking longer than expected. Please try again later.");
    };

    useEffect(() => {
        setTestCases((prev) =>
            prev.map((tc) => ({
                ...tc,
                status: 'pending',
                loading: false,
                result: null,
                expanded: false,
            }))
        );
    }, []);

    return testCases.length > 0 ? (
        <div style={{ margin: '4px' }}>

            <div className="controls-container">
                <Select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    style={{ marginBottom: '10px', height: '30px' }}
                >
                    <MenuItem value="java">Java</MenuItem>
                </Select>

                <button className="zoom-button" onClick={toggleContentCollapse}>
                    {!isCollapsed ? (
                        <span className="dotted-bracket">[ ]</span>
                    ) : (
                        <span className="dotted-bracket">✖</span>
                    )}
                </button>
            </div>
            <CodeMirror
                value={code}
                height="480px"
                extensions={[getLanguageExtension()]}
                onChange={(value) => setCode(value)}
                theme={oneDark}
            />

            <div style={{ display: 'flex', gap: '20px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={runFirstTestCase}
                    disabled={batchLoading}
                    style={{ width: '161px', height: '47px', marginTop: '5px' }}
                >
                    {batchLoading ? <CircularProgress size={24} /> : 'Submit Code'}
                </Button>
                {/* 
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submitBatch}
                    disabled={batchLoading}
                    style={{ width: '161px', height: '47px', marginTop: '5px' }}
                >
                    {batchLoading ? <CircularProgress size={24} /> : 'Submit'}
                </Button> */}

                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchComplexity}
                    disabled={openAIButtonLoading}
                    style={{ maxWidth: '250px', height: '47px', marginTop: '5px', background:'#4169E1' }}
                >
                    {openAIButtonLoading ? <CircularProgress size={24} /> : 'Check Code Complexity ?'}
                </Button> */}
            </div>
            {batchStatus && (
                <div style={{ marginTop: '10px' }}>
                    {batchStatus.includes('Error') ? (
                        <Typography variant="body1" style={{ color: 'red', fontSize: '1.2rem' }}>
                            {batchStatus}
                        </Typography>
                    ) : (
                        <Typography variant="body1" style={{ color: 'green', fontSize: '1.2rem' }}>
                            Result: {batchStatus}
                        </Typography>
                    )}
                </div>
            )}
            <Grid container spacing={1}>
                {testCases.map((testCase) => (
                    <Grid item xs={2} key={testCase.id}>
                        <Button
                            fullWidth
                            variant="contained"
                            color={
                                testCase.status === 'passed'
                                    ? 'success'
                                    : testCase.status === 'failed'
                                        ? 'error'
                                        : 'primary'
                            }
                            onClick={() => setTestCaseExpanded(testCase)}
                            disabled={testCase.id !== 1 && (testCase.status === 'pending' || batchLoading)}
                            style={{ marginBottom: '5px', marginTop: '10px', padding: '10px', fontSize: '0.75rem' }}
                        >
                            {`Test ${testCase.id} ${testCase.status === 'passed' ? '✔' : testCase.status === 'failed' ? '✘' : ''}`}
                        </Button>
                    </Grid>
                ))}
                {complexityData && (
                    <div style={{ width: '100%', marginTop: '10px', backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '5px' }}>
                        <Typography variant="subtitle2">Time Complexity:</Typography>
                        <pre>{`Type: ${complexityData.timeComplexity.type}`}</pre>

                        <Typography variant="subtitle2" style={{ marginTop: '10px' }}>Space Complexity:</Typography>
                        <pre>{`Type: ${complexityData.spaceComplexity.type}`}</pre>
                    </div>
                )}


                {error && <Typography color="error">{error}</Typography>}
                {testCases && (
                    <div style={{ width: '100%', marginTop: '10px', backgroundColor: '#f9f9f9', padding: '10px', borderRadius: '5px' }}>
                        {testCaseExpanded ?
                            (
                                <div key={testCaseExpanded.id}>
                                    <Typography variant="subtitle2" style={{ fontSize: '0.75rem' }}><strong>TestCase {testCaseExpanded.id}:</strong></Typography>
                                    <Typography variant="subtitle2" style={{ fontSize: '0.75rem' }}>Input</Typography>
                                    <pre style={{ fontSize: '0.75rem', background: 'white' }}>{testCaseExpanded.input}</pre>
                                    <Typography variant="subtitle2" style={{ fontSize: '0.75rem' }}>Expected Output:</Typography>
                                    <pre style={{ fontSize: '0.75rem', background: 'white' }}>{testCaseExpanded.expectedOutput}</pre>
                                    {testCaseExpanded.result && (
                                        <>
                                            <Typography variant="subtitle2" style={{ fontSize: '0.75rem' }}>Actual Output:</Typography>
                                            <pre style={{ fontSize: '0.75rem', background: 'white' }}>{testCaseExpanded.result}</pre>
                                        </>
                                    )}
                                </div>
                            ) : null
                        }
                    </div>
                )}
            </Grid>
        </div>
    ) : null;
};

export default CodeEditor;
