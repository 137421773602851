import React, { useEffect } from 'react';
import '../css/successpop.css'


const SuccessPopup = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Hide the message after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div className="success-popup">
      <div className="success-popup-content">
        <span className="success-icon">🎉</span>
        <span className="success-message">{message}</span>
        <div className="button-container">
          <button className="ok-button" onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;
