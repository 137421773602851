import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { ContactSupport, Height } from '@mui/icons-material';
import { margin, textAlign, width } from '@mui/system';

function Contact() {
  return (
    <div style={{ textAlign: 'center' }}>
    <img src='https://course-image-data.s3.ap-south-1.amazonaws.com/contact.png' className='contactPage'/>
      <div style={styles.contactInfo}>
        <p style={styles.text}>
          <strong>Phone:</strong><a href="tel:+916362845832" style={styles.link}>+916362845832</a>
        </p>
        <p style={styles.text}>
          <strong>Email:</strong> <a href="mailto:customercare@byteu.in" style={styles.link}>customercare@byteu.in</a>
        </p>
      </div>
      <div style={styles.socialMedia}>
        <p style={styles.text}>Follow us on:</p>
        <a href="https://www.instagram.com/byteuni/" style={styles.socialLink} aria-label="Instagram">
          <FontAwesomeIcon icon={faInstagram} style={styles.icon} />
        </a>
        <a href="https://www.linkedin.com/company/byte-university" style={styles.socialLink} aria-label="LinkedIn">
          <FontAwesomeIcon icon={faLinkedin} style={styles.icon} />
        </a>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  contactInfo: {
    margin: '20px 0',
    textAlign:'center',
    margin: 'auto',
    width:'100%'
  },
  text: {
    fontSize: '16px',
    margin: '10px 0',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
  socialMedia: {
    marginTop: '20px',
    width:'98%',
  },
  socialLink: {
    margin: '0 10px',
    color: '#007bff',
    textDecoration: 'none',
    fontSize: '24px', // Adjust icon size
  },
  icon: {
    margin: '0 10px',
  }
};

export default Contact;
