import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { Context } from "../context";
import { redirectTo } from "../component/Redirect.js";
import { red } from "@mui/material/colors";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    const { state, dispatch } = useContext(Context);
    const { user } = state;

    useEffect(() => {
        if (user !== null) {
            redirectTo("/");
        }
    }, [user]);

    const clearForm = () => {
        setUsername("");
        setPassword("");
    };

    const data = {
        usernameOrEmail: username,
        password: password,
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const uri = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN_URL}`;
            const res = await axios.post(uri, data);

            toast.success("Login Success Redirecting to Home page");
            console.log("response", res.data);

            setLoading(false);
            dispatch({
                type: "LOGIN",
                payload: res.data.responseData,
            });

            setErrorMsg(null)
            // Save user data in local storage
            window.localStorage.setItem("user", JSON.stringify(res.data.responseData));

            // Redirect to home page
            redirectTo("/");
        } catch (err) {
            console.log("Error login", err);
           
            try {
                setErrorMsg(err.response.data.statusInfo.errorMessage);
                setLoading(false);
            } catch (err1) {
                console.log("Error login", err1);
                setErrorMsg("There is some error logging in, please try again later");
                setLoading(false);
            }

            clearForm();
        }
    };

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>

            <div className="container col-md-10 mt-5 border rounded p-4">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center" style={{ padding: '2px' }}>
                        <img
                            src="https://course-image-data.s3.ap-south-1.amazonaws.com/login.png"
                            alt="About Us"
                            className="img-fluid"
                            style={{ maxWidth: '100%', height: '550px' }}
                        />
                    </div>

                    <div className="col-md-1 d-none d-md-block border-right"></div>
                    <div className="col-md-5 d-flex align-items-center justify-content-center">
                        <div className="text-center LoginPage">
                            <h2>Login</h2>
                           <h7 className="text-danger">{errorMsg}</h7>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control mb-4 p-4"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder="Enter username or email"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control mb-4 p-4"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter password"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-block p-2 btnLogin"
                                    disabled={!username || !password || loading}
                                    
                                >
                                    {loading ? (
                                        <CircularProgress style={{ marginRight: 10 }} size={40} />
                                    ) : (
                                        "Submit"
                                    )}
                                </button>
                            </form>
                            <p className="text-center mt-3">
                                Create New Account? <Link to="/register">Register</Link>
                            </p>
                            <p className="text-center">
                                <Link className="text-danger" to="/forgot-password">
                                    Forgot password
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
