import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const InstructorNav = () => {
    const location = useLocation();
    const [current, setCurrent] = useState('');

    useEffect(() => {
        setCurrent(location.pathname);
    }, [location.pathname]);

    return (
        <div className="nav flex-column nav-pills" >
            <a href="/instructor" className={`nav-link ${current === "/instructor" ? "active" : ""}`} style={{ color: '#333', borderRadius: '0' }}>Dashboard</a>
            <a href="/instructor/course/create" className={`nav-link ${current === "/instructor/course/create" ? "active" : ""}`} style={{ color: '#333', borderRadius: '0' }}>Create New Course</a>
            <a href="/instructor/blog/create" className={`nav-link ${current === "/instructor/blog/create" ? "active" : ""}`} style={{ color: '#333', borderRadius: '0' }}>Create Blog</a>
            <a href="/instructor/course/test" className={`nav-link ${current === "/instructor/course/test" ? "active" : ""}`} style={{ color: '#333', borderRadius: '0' }}>Test</a>
        </div>
    );
};

export default InstructorNav;
