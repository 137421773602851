import React from 'react';
import { format } from 'date-fns';

export default function Post({ title, summary, cover, createdAt, createdBy, slug }) {
    const currentDate = new Date();
    return (
        <div className="post">
            <div className="post-image-list">
                {/* Apply styles to constrain the image size */}
                <img src={cover} alt="Cover Image" style={{ maxWidth: '100%', height: '200px' }} />
            </div>
            <div className="texts">
                <a href={`/blog/${slug}`}>
                    <h3>{title}</h3>
                    <p className="info">
                        <span className="author">{createdBy}</span>
                        <time>{format(new Date(createdAt), 'MMM d, yyyy')}</time>
                    </p>
                </a>
            </div>
        </div>
    );
}
