import { useEffect, useState } from "react";
import ExpandableTopic from "../../pages/course/course-list";

const styles = {
  box: {
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    width: '69%',
    boxShadow: 'rgb(237 224 224) 5px 6px'
  },
  list: {
    paddingLeft: '20px',
    listStyleType: 'disc',
  },
  listItem: {
    marginBottom: '10px',
  },
};


const SingleCourseLessions = ({ topics, course, enrolled , user}) => {


  const [lessons, setLessons] = useState([]);
  useEffect(() => {
    setLessons(convertTopics(topics));
  }, []);


  function convertTopics(response) {
    // Check if response and responseData exist
    if (!response || !Array.isArray(response)) {
      return []; // Return empty array if response format is incorrect
    }

    // Convert response to array of topics with lessons
    const topicsArray = response.reduce((acc, topic) => {
      // Check if topic_id already exists in accumulator
      const existingTopic = acc.find(t => t.topicSlug === topic.topicSlug);

      if (existingTopic) {
        // If topic_id already exists, push lesson to existing topic's lessons array
        existingTopic.lessons.push({
          externalId: topic.externalId,
          rank: topic.rank,
          description: topic.description,
          videoUrl: topic.videoUrl,
          pdfUrl: topic.pdfUrl,
          name: topic.name,
          paid: topic.paid
        });
      } else {
        // If topic_id doesn't exist, create a new topic object
        acc.push({
          topicName: topic.topicName,
          topicSlug: topic.topicSlug,
          topicId: topic.topicId,
          lessons: [
            {
              externalId: topic.externalId,
              rank: topic.rank,
              description: topic.description,
              videoUrl: topic.videoUrl,
              pdfUrl: topic.pdfUrl,
              name: topic.name,
              paid: topic.paid
            }
          ]
        });
      }

      return acc;
    }, []);

    return topicsArray;
  }

  return <>

    <div className="container">
      <div className="row">
      <div className="col-md-12">
          <div className="col topic-list">
          <ExpandableTopic topics={lessons}  preview={false} enrolled={enrolled} user={user} course={course}/>
        </div>
        </div>
        
      </div>
    </div>
  </>
};



export default SingleCourseLessions;