import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  IconButton
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { red } from '@mui/material/colors';
import { redirectTo } from '../../component/Redirect';

const ExpandableTopic = ({ topics, preview, enrolled, user, canAddCode ,course}) => {
  const [expanded, setExpanded] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showHTMLContent, setShowHTMLContent] = useState(false);
  const [htmlContent, setHtmlContent] = useState(null);
  const [showCodeEditorModal, setShowCodeEditorModal] = useState(false); // State to handle code editor modal
  const [selectedLesson, setSelectedLesson] = useState(null); // Track the selected lesson for code editor
  const [isVisible, setVisible] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleLessonClick = async (lesson) => {
    if (lesson.paid && !enrolled) {
      alert('Please enroll to view this lesson.');
      return;
    }

    redirectTo(`/user/course/${course.name}`,{lessonId:lesson.externalId});

  }




  return (
    <div style={{ width: '90%', border: '1px solid #e2dbdb', borderRadius: '10px' }}>
      {topics && topics.length > 0 && topics.map((topic) => (
        <Accordion className='accord-toggle'
          key={topic.topicId}
          expanded={expanded === topic.topicId}
          onChange={handleChange(topic.topicId)}
          style={{ marginBottom: '1px', width: '100%' }}
        >
          <AccordionSummary
            aria-controls={`panel${topic.topicId}-content`}
            id={`panel${topic.topicId}-header`}
            onClick={() => handleChange(topic.topicId)}
            style={{
              backgroundColor: 'rgb(248 249 250)',
              minHeight: '30px',
              height: '80px',
              alignItems: 'center',

              cursor: 'pointer'
            }}
          >
            <Typography style={{ flexGrow: 1, paddingTop: '8px', paddingLeft: '6px', color: 'black', textAlign: 'left' }}>{topic.topicName}</Typography>
            <Button
              variant="link"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleChange(topic.topicId);
              }}
            >
              {expanded === topic.topicId ? (
                <i className="bi bi-chevron-up" style={{ cursor: 'pointer' }}></i>
              ) : (
                <i className="bi bi-chevron-down" style={{ cursor: 'pointer' }}></i>
              )}
            </Button>
          </AccordionSummary>
          <AccordionDetails style={{
            backgroundColor: 'white',
            padding: '2px 8px',
            minHeight: '50px'
          }}>
            <List component="nav" aria-label="lessons">
              {topic.lessons && topic.lessons.length > 0 && topic.lessons.map((lesson, index) => (
                <div key={lesson.externalId}>
                  <ListItem
                    button
                    onClick={() => handleLessonClick(lesson)}
                  >
                    <ListItemText primary={lesson.name} />
                    <IconButton disabled={lesson.paid} >
                      {lesson.paid ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  </ListItem>
                  {index !== topic.lessons.length - 1 && <Divider />}
                </div>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ExpandableTopic;
