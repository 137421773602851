import React, { useState, useRef , useContext} from "react";
import axios from "axios";
import CourseCreateForm from "../../../component/forms/CourseCreateForm";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { redirectTo } from "../../../component/Redirect";
import '../../../css/blog.css'
import { Context } from "../../../context";

const CourseCreate = () => {
  const [values, setValues] = useState({
    name: "",
    description: "",
    price: "9.99",
    uploading: false,
    paid: true,
    loading: false,
    releaseDate:'',
    level:'BEGINNERS',
    category: "GATE",
    imagePreview: "",
    learningObjectives:''
  });

  const fileInputRef = useRef(null);
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");
  const {state} = useContext(Context)
  const {user} = state;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleRemoveImage = (e) => {
    setValues({ ...values, loading: true });
    setUploadButtonText("Upload Image");
    setImage("");
    setPreview("");
    setValues({ ...values, loading: false });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(e.target.files[0]));
    setUploadButtonText(file.name);
    setValues({ ...values, loading: true });

    try {
      Resizer.imageFileResizer(
        file,
        1500,
        1500,
        "JPEG",
        90,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/v1/courses/upload-image`,
              {
                image: uri,
              },
              {headers: {
                Authorization: "Bearer " + user.accessToken,
                "Content-Type": "application/json",
              }}
            );
            console.log("Image Uploaded", data);
            setValues({ ...values, loading: false });
            setImage(data);
          } catch (err) {
            console.log(err);
            setValues({ ...values, loading: false });
          }
        },
        "base64"
      );
    } catch (err) {
      console.log(err);
      setValues({ ...values, loading: false });
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const courseReq = {
      paid: values.paid,
      name: values.name,
      price: parseFloat(values.price),
      level:values.level,
      releaseDate:values.releaseDate,
      courseAdditionalInfo: {
        description: values.description,
        learningObjectives: values.learningObjectives
      },
      category: values.category,
      url: image,
    };

    try {
      let { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/courses`,
        courseReq,{
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
          }
        }
      );
      console.log("Course Created", data);
      toast.success("Course successfully created");
      // Redirect to instructor dashboard or any other desired page
      // Replace with your routing logic for redirecting after successful course creation
      // Example:
      redirectTo("/instructor");
    } catch (err) {
      console.error("Error creating course", err);
      toast.error("Failed to create course. Please try again.");
    }
  };

  return (
    <div className="create-post-container1">
     <h2>Create Course</h2>
    <div className="pt-3 pb-3">
      <CourseCreateForm
        handleSubmit={handleSubmit}
        handleImage={handleImage}
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        preview={preview}
        uploadButtonText={uploadButtonText}
        handleRemoveImage={handleRemoveImage}
        fileInputRef={fileInputRef}
        image={image}
      />
    </div>
  </div>

  );
};

export default CourseCreate;
