import { useState, useEffect,useRef , useContext} from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import { Select, MenuItem, Avatar, Modal, Box , Typography} from "@mui/material";
import { Tooltip, Button, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Markdown from "react-markdown";
import AddLessonForm from "../../../../component/forms/AddLessonForm";
import { toast } from "react-toastify";
import CourseCreateForm from "../../../../component/forms/CourseCreateForm";
import Resizer from "react-image-file-resizer";
import { redirectTo } from "../../../../component/Redirect";
import { Context } from "../../../../context";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const CourseEdit = () => {

    const [values, setValues] = useState({
        courseId:'',
        name: '',
        description: '',
        price: '9.99',
        uploading: false,
        paid: true,
        loading: false,
        category:'GATE',
        imagePreview: '',
        learningObjectives:''
    });

    const fileInputRef = useRef(null);
    const[image, setImage] = useState('');
    const[courseId, setCourseId] = useState('');
    const [preview, setPreview] = useState('');
    const [uploadButtonText ,setUploadButtonText] = useState('Upload Image')
    const {state} = useContext(Context)
    const {user} = state;
    const handleChange = (e)=> {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleRemoveImage = (e)=> {
        setValues({...values, loading: true});
            setUploadButtonText('Upload Image');
            setImage('');
            setPreview('');
        setValues({...values, loading: false})
        
    };

    const { slug } = useParams();

    useEffect(() => {
        console.log(slug)
        if(user)loadCourse();

    }, [slug, user]);

    const loadCourse = async () => {
        try {
            let { data } = await axios.get(process.env.REACT_APP_BASE_URL + `/v1/courses/${slug}`,{
                headers: {
                  Authorization: "Bearer " + user.accessToken,
                  "Content-Type": "application/json",
                }
              });
            if(data.responseData){
                 setValues({...values,
                    name:data.responseData.name,
                    description:data.responseData.courseAdditionalInfo.description,
                    learningObjectives:data.responseData.courseAdditionalInfo.learningObjectives,
                    price:data.responseData.price,
                    paid:data.responseData.paid});
                 setImage(data.responseData.url)
                 setCourseId(data.responseData.externalId);
            }
        } catch (err) {
            console.log(err);
        }
    }


    const handleImage = (e) => {
        let file = e.target.files[0];
       setPreview(window.URL.createObjectURL(e.target.files[0]));
       setUploadButtonText(file.name);
       setValues({...values, loading:true});
       console.log(Resizer)
       try {
        Resizer.imageFileResizer(
            file,
            1500,
            1500,
            "JPEG",
            90,
            0,
            async (uri) => {
                try {
                    let { data } = await axios.post(process.env.REACT_APP_BASE_URL+"/v1/courses/upload-image", {
                        image: uri,
                    },{
                        headers: {
                          Authorization: "Bearer " + user.accessToken,
                          "Content-Type": "application/json",
                        }
                      });
                    console.log("Image Uploaded", data);
                    setValues({ ...values, loading: false });
                    setImage(data);
                } catch (err) {
                    console.log(err);
                    setValues({ ...values, loading: false });
                }
            },
            'base64'
        );
      } catch (err) {
        console.log(err);
        setValues({ ...values, loading: false });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    
};

    const handleSubmit = async (e)=> {
        e.preventDefault();
        const courseReq = {
            paid:values.paid,
            name:values.name,
            price:parseFloat(values.price),
            courseAdditionalInfo:{
                description: values.description,
                learningObjectives:values.learningObjectives
            },
            category:values.category,
            url:image
        }
        console.log(courseReq)
        try {
            let { data } = await axios.patch(process.env.REACT_APP_BASE_URL+`/v1/courses/${courseId}`, courseReq,{
                headers: {
                  Authorization: "Bearer " + user.accessToken,
                  "Content-Type": "application/json",
                }
              });
            console.log("Image Uploaded", data);
            toast("Successfully Updated")
            redirectTo("/instructor")    
        } catch (err) {
            console.log(err);
            toast(err.response)
        }
    };

   

    return (
        <div className="create-post-container">
        <h2>Edit Course</h2>
       <div className="pt-3 pb-3">
         <CourseCreateForm
           handleSubmit={handleSubmit}
           handleImage={handleImage}
           handleChange={handleChange}
           values={values}
           setValues={setValues}
           preview={preview}
           uploadButtonText={uploadButtonText}
           handleRemoveImage={handleRemoveImage}
           fileInputRef={fileInputRef}
           image={image}
           editPage={true}
         />
       </div>
     </div>
    );
};


export default CourseEdit;