import { Button, Box } from "@mui/material";
import React, { useState, useRef, useEffect, Suspense } from 'react';
import 'react-quill/dist/quill.snow.css'; // Import Quill snow theme
import 'highlight.js/styles/default.css'; // Import highlight.js default CSS
import { TextField, Select, MenuItem } from "@mui/material";
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import python from 'highlight.js/lib/languages/python';
import go from 'highlight.js/lib/languages/go';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml'; // For HTML/CSS
import context from "react-bootstrap/esm/AccordionContext";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import MathJax from 'react-mathjax2';

// Lazy load ReactQuill component
const ReactQuill = React.lazy(() => import('react-quill'));

// Register languages for syntax highlighting with highlight.js
hljs.registerLanguage('java', java);
hljs.registerLanguage('python', python);
hljs.registerLanguage('go', go);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('html', xml);
hljs.registerLanguage('css', xml);

const customStyles = `
  .ql-editor pre {
    max-width: 50%;
    margin: 0 auto;
    overflow-x: auto;
  }
`;

const AddLessonForm = ({ values, setValues, handleAddLesson, handleClose, uploadVideoButtonText, handleVideo, progress, handlePDF, uploadPDFButtonText, user }) => {
    const [quillKey, setQuillKey] = useState(Date.now());
    const quillRef = useRef(null);
    const [previewContent, setPreviewContent] = useState(''); // State to store preview content
    const [showPopup, setShowPopup] = useState(false); // State to toggle popup visibility

    const handleAdd = () => {
        const content = quillRef.current.value;
        handleAddLesson(content);
    };

    const handlePreview = () => {
        if (quillRef.current) {
            // Get the HTML content from the ReactQuill editor
            const editorContent = quillRef.current.getEditor().root.innerHTML;
            setPreviewContent(editorContent); // Set the preview content
            setShowPopup(true); // Show the popup
        }
    };

    // Function to close the popup
    const closePopup = () => {
        setShowPopup(false);
    };

    const modules = {
        syntax: {
            highlight: text => hljs.highlightAuto(text).value, // Enable syntax highlighting
        },
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, false] }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                ['code-block'],
                ['latex'],
                ['clean']
            ],
            handlers: {
                image: () => imageHandler(), // Add custom image handler
                latex: () => insertLatex() // Add custom LaTeX handler
            }
        }
    };

    useEffect(() => {
        setQuillKey(Date.now());
    }, [values.title, values.paid, values.testAvailable, values.topic, values.codeEditor]);

    const insertLatex = () => {
        const latex = prompt("Enter LaTeX formula:");
        if (latex) {
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            quill.insertText(range.index, `\\(${latex}\\)`, 'user'); // Insert LaTeX into the editor
        }
    };

    async function imageHandler() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file.type === "image/gif") {
                // Handle GIF differently
                try {
                    // Upload GIF using your existing API endpoint
                    let { data } = await axios.post(
                        `${process.env.REACT_APP_BASE_URL}/v1/courses/upload-gif`,
                        {
                            file: file, // Send the original file
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + user.accessToken,
                                "Content-Type": "multipart/form-data", // Ensure multipart format for file upload
                            }
                        }
                    );
                    console.log("GIF Uploaded", data);
                    if (quillRef.current) {
                        const quill = quillRef.current.getEditor();
                        const range = quill.getSelection(); // Get the current selection
                        quill.insertEmbed(range.index, 'image', data); // Insert the GIF at the current selection
                    }
                } catch (err) {
                    console.log(err);
                    setValues({ ...values, loading: false });
                }

            } else {
                Resizer.imageFileResizer(
                    file,
                    1500,
                    1500,
                    "JPEG",
                    90,
                    0,
                    async (uri) => {
                        try {
                            let { data } = await axios.post(
                                `${process.env.REACT_APP_BASE_URL}/v1/courses/upload-image`,
                                {
                                    image: uri,
                                },
                                {
                                    headers: {
                                        Authorization: "Bearer " + user.accessToken,
                                        "Content-Type": "application/json",
                                    }
                                }
                            );
                            console.log("Image Uploaded", data);
                            if (quillRef.current) {
                                const quill = quillRef.current.getEditor();
                                const range = quill.getSelection(); // Get the current selection
                                quill.insertEmbed(range.index, 'image', data); // Insert image at the current selection
                            }
                        } catch (err) {
                            console.log(err);
                            setValues({ ...values, loading: false });
                        }
                    },
                    "base64"
                );

            }

        };
    }

    return (
        <div className="container pt-3">
            <form onSubmit={handleAddLesson}>
                <TextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setValues({ ...values, title: e.target.value })}
                    value={values.title}
                    placeholder="Title"
                    autoFocus
                    required
                />
                <div className="form-row">
                    <style>{customStyles}</style>
                    <Suspense fallback={<div>Loading editor...</div>}>
                        <ReactQuill
                            key={quillKey}
                            ref={quillRef}
                            modules={modules}
                            placeholder="Write something..."
                            style={{ height: '300px' }}
                        />
                    </Suspense>
                </div>
                <div className="form-row">
                    <button onClick={handlePreview} style={{ marginTop: '20px' }}>
                        Preview
                    </button>
                </div>
                {showPopup && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <button onClick={closePopup} className="close-btn">
                                Close
                            </button>
                            <h3>Preview</h3>
                            <MathJax.Context>
                                <div
                                    className="preview-content"
                                    dangerouslySetInnerHTML={{ __html: previewContent }}
                                />
                            </MathJax.Context>

                        </div>
                    </div>
                )}
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <Select
                                style={{ width: "100%" }}
                                size="large"
                                value={values.paid}
                                onChange={(v) => setValues({ ...values, paid: !values.paid })}>
                                <MenuItem value={true}>Paid</MenuItem>
                                <MenuItem value={false}>Free</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <h6>Tests Available</h6>
                            <Select
                                style={{ width: "100%" }}
                                size="large"
                                value={values.testAvailable}
                                onChange={(v) => setValues({ ...values, testAvailable: !values.testAvailable })}>
                                <MenuItem value={true}>YES</MenuItem>
                                <MenuItem value={false}>NO</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <h6>Code Editor Required</h6>
                            <Select
                                style={{ width: "100%" }}
                                size="large"
                                value={values.codeEditor}
                                onChange={(v) => setValues({ ...values, codeEditor: !values.codeEditor })}>
                                <MenuItem value={true}>YES</MenuItem>
                                <MenuItem value={false}>NO</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
               
                <TextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setValues({ ...values, topic: e.target.value })}
                    value={values.topic}
                    placeholder="Topic Category"
                    autoFocus
                    required
                />
                <div>
                    {/* Upload Video */}
                    <div className="mt-3">
                        <label className="btn btn-dark btn-block text-left">
                            {uploadVideoButtonText}
                            <input onChange={handleVideo} type="file" accept="video/*" hidden />
                        </label>
                    </div>

                    {/* Upload PDF */}
                    <div className="mt-3">
                        <label className="btn btn-dark btn-block text-left">
                            {uploadPDFButtonText}
                            <input onChange={handlePDF} type="file" accept=".pdf" hidden />
                        </label>
                    </div>
                </div>

                {progress > 0 && <div>
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                        {[...Array(10)].map((_, index) => (
                            <div key={index} style={{ position: 'relative', flex: 1 }}>
                                <div
                                    style={{
                                        flex: 1,
                                        height: 10,
                                        backgroundColor: index * 10 <= progress ? '#4CAF50' : '#CCC',
                                        marginRight: index === 9 ? 0 : 2,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>}

                <Box mt={4} display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" onClick={handleAdd}>
                        Save
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ ml: 2 }}>
                        Cancel
                    </Button>
                </Box>
            </form>
        </div>
    );
};

export default AddLessonForm;
