import { useState, useEffect } from "react";
import { Select , MenuItem, Avatar} from "@mui/material";
import { Button,  Box,MailIcon, } from '@mui/material';
import { Preview, SaveOutlined  } from "@mui/icons-material";

import { TextField } from '@mui/material';

const CourseCreateForm = ({handleSubmit, 
    handleImage,
    handleChange,
    values, 
    setValues,
    preview, 
    uploadButtonText,
    handleRemoveImage,
    fileInputRef, 
    image,
    editPage = false
  }) => {

    

    const categoryLists = []
    categoryLists.push(<MenuItem value = 'gate'>GATE</MenuItem>)
    categoryLists.push(<MenuItem value = 'interview'>INTERVIEW</MenuItem>)
    categoryLists.push(<MenuItem value = 'tech'>TECH</MenuItem>)


    const levelLists = []
    levelLists.push(<MenuItem value = 'beginners'>BEGINNERS</MenuItem>)
    levelLists.push(<MenuItem value = 'advanced'>ADVANCED</MenuItem>)
    levelLists.push(<MenuItem value = 'experienced'>EXPERIENCED</MenuItem>)

    return (
     <form onSubmit={handleSubmit}>

        <div className="form-group1">
            <input type="text" 
             name="name"
             className="form-control"
             placeholder="Name"
             value={values.name}
             onChange={handleChange}></input>
        </div>

        <div className="form-group1">     
          <textarea 
          name="description"
          placeholder="Description"
          cols="7"
          rows="7"
          value={values.description}
          className="form-control"
          onChange={handleChange}
          ></textarea>           
        </div>

        <div className="form-group1">     
          <textarea 
          name="learningObjectives"
          placeholder="Learning Objectives"
          cols="7"
          rows="7"
          value={values.learningObjectives}
          className="form-control"
          onChange={handleChange}
          ></textarea>           
        </div>
        <div className="form-group">
                <input
                    type="date"
                    name="releaseDate"
                    className="form-control"
                    value={values.releaseDate}
                    onChange={(e) => setValues({ ...values, releaseDate: e.target.value })}
                />
            </div>
        <div className="form-row1">
          <div className="col">
           <div className="form-group">     
                 <Select 
                 style={{width: "100%"}}
                 size="large"
                 value = {values.paid}
                 onChange={(v)=> setValues({...values, paid:!values.paid})}>
                    <MenuItem value={true}>Paid</MenuItem>
                    <MenuItem value={false}>Free</MenuItem>
                 </Select>
            </div>
           </div>
        </div>

        {values.paid && 
         <div className="col-md-6">
         <div className="form-group1">     
            <TextField
                style={{ width: '100%' }}
                value={values.price}
                onChange={(e) => setValues({ ...values, price: e.target.value })}
                placeholder="Enter price"
                variant="outlined"
                type="number"
            />
          </div>
         </div>
        }
       {editPage ?  null : <div className="col-md-6">
         <div className="form-group1">     
               <Select 
               style={{width: "100%"}}
               value = {values.category}
               onChange={(v)=> setValues({...values, category:v.target.value})}
               >
                 {categoryLists}
               </Select>
          </div>
         </div>}
         <div className="form-group1">     
               <Select 
               style={{width: "100%"}}
               value = {values.level}
               onChange={(v)=> setValues({...values, level:v.target.value})}
               >
                 {levelLists}
               </Select>
          </div>
        <div className="form-row">
           <div className="col">
            <div className="form-group1">

                 <label className="btn btn-outline-secondary btn-block text-left">
                      {uploadButtonText}
                     {values.loading ? 'Uploading': null}
                     <input type="file" name="image" onChange={handleImage}
                     accept="image/*"
                     hidden
                     ref={fileInputRef}
                     ></input>
                 </label>
            </div>
           </div>
        </div>
      


        {preview && (
               <>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                <img src={preview} alt="Uploaded" style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }} />
                <button onClick={handleRemoveImage} style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  borderRadius: '30%',
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer'
                }}>×</button>
        </div>
              </>
        )}

        {editPage &&  <div>
          <img src={image} alt="Uploaded" style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }} />
        </div>}

        <div className="row">
           <div className="col">

                 <Button 
                 onClick={handleSubmit}
                 disabled={values.loading || values.uploading}
                 className="btn btn-primary"
                 icon={<SaveOutlined/>}>

                     {values.loading ? "Saving ...": "Save & Continue"}
                 </Button>
            </div>
        </div>
    </form>
)
};


export default CourseCreateForm;