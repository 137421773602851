import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import axios from 'axios';

const AddTestModal = ({ open, onClose, user }) => {
  const [testName, setTestName] = useState('');
  const [testDescription, setTestDescription] = useState('');
  const [timeLimit, setTimeLimit] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [courses, setCourses] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingTopics, setLoadingTopics] = useState(false);

  useEffect(() => {
    if (open) {
      fetchCourses();
    }
  }, [open]);

  useEffect(() => {
    if (selectedCourse) {
      fetchTopics(selectedCourse.externalId);
    }
  }, [selectedCourse]);

  const fetchCourses = async () => {
    setLoadingCourses(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/list`,{ headers: {
        Authorization: "Bearer " + user.accessToken,
        "Content-Type": "application/json",
    }});
      setCourses(response.data.responseData);
    } catch (error) {
      console.error('Error fetching courses', error);
    } finally {
      setLoadingCourses(false);
    }
  };

  const fetchTopics = async (courseId) => {
    setLoadingTopics(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/courses/${courseId}/topics/list`,{
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "application/json",
      }
      }); // Adjust URL based on your API
      setTopics(response.data.responseData);
    } catch (error) {
      console.error('Error fetching topics', error);
    } finally {
      setLoadingTopics(false);
    }
  };

  const handleSubmit = async () => {
    const newTest = {
      name: testName,
      description: testDescription,
      testDuration:timeLimit,
      courseId: selectedCourse?.externalId,
      topicId: selectedTopic?.topicId,
    };

    setLoadingTopics(true);
    try {
      const response =  await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/tests`,
        newTest,{
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "Content-Type": "application/json",
        }
        }
      );
      
    } catch (error) {
      console.error('Error Creating tests', error);
    } finally {
      setLoadingTopics(false);
    }

    setTestName('');
    setTestDescription('');
    setTimeLimit('');
    setSelectedCourse(null);
    setSelectedTopic(null);
    onClose();
    window.location.reload();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Test</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Test Name"
            fullWidth
            value={testName}
            onChange={(e) => setTestName(e.target.value)}
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={testDescription}
            onChange={(e) => setTestDescription(e.target.value)}
          />
          <TextField
            label="Time Limit (minutes)"
            type="number"
            fullWidth
            value={timeLimit}
            onChange={(e) => setTimeLimit(e.target.value)}
          />
          <Autocomplete
            options={courses}
            getOptionLabel={(option) => option.name || ''}  // Display course name
            value={selectedCourse}
            onChange={(e, newValue) => setSelectedCourse(newValue)}
            loading={loadingCourses}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Course"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingCourses ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <Autocomplete
            options={topics}
            getOptionLabel={(option) => option.topicName}
            loading={loadingTopics}
            onChange={(e, newValue) => setSelectedTopic(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Topic"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingTopics ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Add Test</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTestModal;
