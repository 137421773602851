import React, { useState, useEffect, useRef, Suspense,useContext } from 'react';
import 'react-quill/dist/quill.snow.css';
import 'highlight.js/styles/default.css';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import { toast } from 'react-toastify';
import { redirectTo } from '../../../component/Redirect';
import '../../../css/blog.css'
import { Context } from '../../../context';
// Highlight.js languages (importing only what's necessary)
import hljs from 'highlight.js/lib/core';
import java from 'highlight.js/lib/languages/java';
import python from 'highlight.js/lib/languages/python';
import go from 'highlight.js/lib/languages/go';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml'; // For HTML/CSS


// Custom CSS for code blocks
const customStyles = `
  .ql-editor pre {
    max-width: 50%;
    margin: 0 auto;
    overflow-x: auto;
  }
`;

// Lazy load ReactQuill component
const ReactQuill = React.lazy(() => import('react-quill'));



// Register the languages
hljs.registerLanguage('java', java);
hljs.registerLanguage('python', python);
hljs.registerLanguage('go', go);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('html', xml); // Register as 'html' for HTML/CSS
hljs.registerLanguage('css', xml); // Register as 'css' for HTML/CSS

const CreatePost = () => {
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadButtonText, setUploadButtonText] = useState('Upload Image');
    const fileInputRef = useRef(null);
    const [quillKey, setQuillKey] = useState(Date.now()); // Key for ReactQuill key prop
    const contentRef = useRef(null); // Ref to store ReactQuill instance
    const {state}= useContext(Context);
    const {user} = state;

    const modules = {
        syntax: {
            highlight: text => hljs.highlightAuto(text).value,
        },
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, false] }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                ['code-block'],
                ['latex'],
                ['clean']
            ],
            handlers: {
                image: () => imageHandler(), // Add custom image handler
            }
        }
     
    };

    const handleRemoveImage = (e) => {
        setUploadButtonText('Upload Image');
        setImageUrl('');
    };


    async function imageHandler() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];

            Resizer.imageFileResizer(
                file,
                1500,
                1500,
                "JPEG",
                90,
                0,
                async (uri) => {
                    try {
                        let { data } = await axios.post(
                            `${process.env.REACT_APP_BASE_URL}/v1/courses/upload-image`,
                            {
                                image: uri,
                            },
                            {
                                headers: {
                                    Authorization: "Bearer " + user.accessToken,
                                    "Content-Type": "application/json",
                                }
                            }
                        );
                        console.log("Image Uploaded", data);
                        if (contentRef.current) {
                            const quill = contentRef.current.getEditor();
                            const range = quill.getSelection(); // Get the current selection
                            quill.insertEmbed(range.index, 'image', data); // Insert image at the current selection
                        }
                    } catch (err) {
                        console.log(err);
                        setLoading(false)
                    }
                },
                "base64"
            );



        };
    }


    const handleImage = async (e) => {
        let file = e.target.files[0];
        setUploadButtonText(file.name);
        try {
            Resizer.imageFileResizer(
                file,
                1500,
                1500,
                "JPEG",
                90,
                0,
                async (uri) => {
                    try {
                        let { data } = await axios.post(process.env.REACT_APP_BASE_URL + "/v1/documents/upload-image", {
                            image: uri,
                        }, {
                            params: {
                                public: true
                            },        
                            headers: {
                              Authorization: "Bearer " + user.accessToken,
                              "Content-Type": "application/json",
                            }
                          });
                        setLoading(false);
                        setImageUrl(data);
                        setQuillKey(Date.now()); // Update key to trigger ReactQuill rerender
                    } catch (err) {
                        console.log(err);
                        setLoading(false);
                    }
                },
                'base64'
            );
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handlePost = async (e) => {
        e.preventDefault();
        const contentBody = contentRef.current.value;
        const postReq = {
            title: title,
            summary: summary,
            coverImage: imageUrl,
            content: contentBody
        };
        try {
            let { data } = await axios.post(process.env.REACT_APP_BASE_URL + "/v1/posts", postReq,{
                headers: {
                  Authorization: "Bearer " + user.accessToken,
                  "Content-Type": "application/json",
                }
              });
            console.log("Post Uploaded", data);
            toast("Successfully uploaded");
            // Uncomment when using routing within your application
            redirectTo("/instructor");
        } catch (err) {
            console.log(err);
            toast(err.response.data);
        }
    };

    useEffect(() => {
        // Whenever title or summary changes, update the key to remount ReactQuill
        setQuillKey(Date.now());
    }, [title, summary]);

    return (
        <div className="create-post-container1">
            <h2>Create Post</h2>
            <form className="create-post-form1" onSubmit={handlePost}>
                <div className="form-group1">
                    <input type="text" placeholder="Title" className="input-field" value={title} onChange={ev => setTitle(ev.target.value)} />
                </div>
                <div className="form-group1">
                    <input type="text" placeholder="Summary" className="input-field" value={summary} onChange={ev => setSummary(ev.target.value)} />
                </div>
                <div className='form-group1'>
                    <label className="btn btn-outline-secondary btn-block text-left"> {loading ? "Upload Image" : imageUrl}
                        <input
                            type="file"
                            name="image"
                            onChange={handleImage}
                            accept="image/*"
                            ref={fileInputRef}
                        />
                    </label>
                </div>
                <div className="form-group1">
                    <style>{customStyles}</style>
                     <Suspense fallback={<div>Loading editor...</div>}>
                        <ReactQuill
                            key={quillKey}
                            ref={contentRef}
                            modules={modules}
                            placeholder="Write something..."
                            style={{ height: '300px' }}
                        />
                    </Suspense>
                </div>
                <div className="button-container1">
                    <button type="submit" className="submit-button">Create Post</button>
                </div>
            </form>
        </div>
    );
};

export default CreatePost;
