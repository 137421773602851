import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import PdfViewer from '../PdfViewer';

const PreviewModalPdf = ({ showModal, setShowModal, src }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>PDF Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
           <PdfViewer pdfUrl={src}  />
      </Modal.Body>
    </Modal>
  );
};

export default PreviewModalPdf;
