import React from "react";
import "../css/LessAbout.css"; // Import the CSS file


const LessAbout = ({ course}) => {

    const halfway = Math.ceil(course.courseAdditionalInfo.learningObjectiveLists.length / 2);
    const column1 = course.courseAdditionalInfo.learningObjectiveLists.slice(0, halfway);
    const column2 = course.courseAdditionalInfo.learningObjectiveLists.slice(halfway);
  return (
    <div className="less-about-container">
      <h2 className="section-title">What You'll Learn</h2>
      <div className="learn-items">
      <div className="column">
        {column1.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
      <div className="column">
        {column2.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
      </div>

      {/* Details to Know Section */}
      <h2 className="section-title">Details to Know</h2>
      <div className="details-grid">
        <div className="detail-box">
          <strong>Duration</strong>
          <p>{course.duration}</p>
        </div>
        <div className="detail-box">
          <strong>Level</strong>
          <p>{course.level.toUpperCase()}</p>
        </div>
        <div className="detail-box">
          <strong>Language</strong>
          <p>English</p>
        </div>
      </div>
    </div>
  );
};

export default LessAbout;
